import React from "react";
import Header from "../Header";
import { GrAnalytics, GrDocumentTime, GrServices } from "react-icons/gr";
import { FaChartPie, FaHelmetSafety, FaPlus, FaPlusMinus } from "react-icons/fa6";
import { FcWorkflow } from "react-icons/fc";
import { TbPresentationAnalytics } from "react-icons/tb";
import { MdAddShoppingCart, MdLocalGroceryStore, MdMobileFriendly, MdOutlineBorderColor, MdOutlineInventory, MdOutlineSettingsInputComposite, MdSecurity, MdWorkHistory } from "react-icons/md";
import { CiCircleMinus } from "react-icons/ci";
import { PiNetworkDuotone, PiProjectorScreenChart } from "react-icons/pi";
import { FaTasks, FaWarehouse } from "react-icons/fa";
import { GiHumanPyramid, GiNotebook } from "react-icons/gi";
import { GoProjectSymlink } from "react-icons/go";
import { AiFillProduct } from "react-icons/ai";
import { LuServerCog } from "react-icons/lu";
const Operation_Consulting = () => {
    return (
        <>
            <div className="oc-main db">
                <div className="oc-inner backgroundimage">
                    <Header />
                    <div className="container">
                        <div className="main-heading">
                            <div className="heading-main operation oc_c ">
                                <div className="item-inner50">
                                    <h1>Operations Management Software </h1><br />
                                    <strong>360-Degree Overview</strong><br /><p><i>Operations management tools allow efficiently managing all operational aspects within a single system instead of 3–5 separate applications that require complex integrations.</i></p><br />
                                </div>
                            </div>
                            <div className="heading-main operation oc_c ">
                                <div className="item-inner50">
                                <p>  <b>Since 1989</b>, ScienceSoft helps companies create effective operations management software tailored to their business needs.</p>
                                    <img className="omsc" src="../assets/images/operations-management-software_cover-new.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="heding">
                        <h2>The Essence of Operations Management Software</h2>
                        <p><b>Operations management software (OMS) </b>helps plan, monitor, and coordinate production and service operations, digitalize daily employee workflows, identify and mitigate operational process bottlenecks.</p>
                    </div>
                    <div className="heading">
                        <h2>Choose the OMS Type You’re Interested In</h2>
                        <div className="item-main50">
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="image green"> <GrServices />
                                    </div>
                                   <div className="line linec"> <h5>Software for service operations</h5></div>
                                    <p>For service- and project-centric companies that need to optimize service-related activities and improve management of human resources, projects, service requests, supply chain, T&E, service-related documents.</p>
                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="image orange"><FaHelmetSafety /> </div>
                                  <div className="line linec">  <h5>Software for production operations</h5></div>
                                    <p>For manufacturing-centric enterprises that need to optimize production activities and improve management of inventory, warehouses, workforce, machinery and equipment maintenance, purchase and sales orders.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="heading">
                        <h2>Factors that Drive ROI of an Operations Management System</h2>
                        <p>In OMS projects, ScienceSoft always seeks to cover the following important factors that may bring additional value for the customer across operations management processes:</p>
                        <div className="item-main50">
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="image"><FcWorkflow /> </div>
                                    <div className="line linec"><h5>End-to-end workflow automation</h5></div>
                                    <p>To improve the productivity of the employees involved in business operations, speed up operational processes, reduce labor costs, and eliminate human errors.</p>
                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="image purple"><TbPresentationAnalytics /></div>
                                   <div className="line linec"> <h5>Comprehensive data analytics</h5></div>
                                    <p>To ensure accurate, data-driven operations planning and get intelligent recommendations on optimal resource utilization and operational risk prevention.</p>
                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="image yellow"><MdSecurity /></div>
                                    <div className="line linec"><h5>Focus on security</h5></div>
                                    <p>To protect the OMS and sensitive data it stores by employing multi-factor authentication, role-based access control, data encryption, AI-powered fraud detection, and other advanced cybersecurity tools.</p>
                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="image"> <MdMobileFriendly /></div>
                                   <div className="line linec"> <h5>Mobile access</h5></div>
                                    <p>To enable the operations teams to control the status of production/service operations on the go, streamline the reporting on operations progress and escalation of operational process issues.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="second db p50">
                    <div className="container">
                        <div className="inner-second">
                            <div className="heading">
                                <h2>OMS Implementation Costs and Financial Outcomes</h2>
                                <span>The cost of OMS implementation varies greatly depending on:</span>
                                <ul>
                                    <li className="kadimadi white" >Functionality scope (the number of functionality modules, implementation of AI features, RFID technology, etc.).</li>
                                    <li className="kadimadi white" >Complexity of data migration procedures (the volume of data that needs to be migrated from the spreadsheets and/or software currently used for operations management).</li>
                                    <li className="kadimadi white" >Complexity and the number of integrations (with ERP, CRM, PLM, etc.).</li>
                                    <p>Developing custom operations management software of average complexity may cost <b> from $200,000 to $400,000,</b> with an expected payback period starting from <b> 3 months.</b></p>
                                    <p>An operations management system is an effective way to optimize <b> gross margin</b> and <b> resource utilization</b>. </p>
                                </ul>
                            </div>
                            <div className="heading">
                                <h2>Custom vs. Off-the-Shelf OMS: Detailed Comparison </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="third db">
                    <div className="container">
                        <div className="inner-third db">
                            <table>
                                <tr>
                                    <td></td>
                                    <th>Ready-made OMS</th>
                                    <th>Custom OMS</th>
                                </tr>
                                <tr><td>TCO components </td>
                                    <td>Initial setup costs + customization and integration costs + licensing fees that scale with the number of OMS users.</td>
                                    <td>Upfront investments + the cost of after-launch support and the evolution of the OMS.</td>
                                </tr>
                                <tr>
                                    <td>Functionality</td>
                                    <td>
                                        <div className="image">
                                            <FaPlusMinus /> </div>
                                        <span>Defined by an OMS product vendor.</span>
                                        <p>Additional features are restricted to third-party plugins and extensions.</p>
                                    </td>
                                    <td>
                                        <div className="image">
                                            <FaPlus />   </div>
                                        <span>Defined by customers and fully tailored to their needs.</span>
                                        <p>New features can be introduced on demand.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Customization </td>
                                    <td>
                                        <div className="image">
                                            <CiCircleMinus />  </div>
                                        <span>Limited capabilities.</span>
                                    </td>
                                    <td>
                                        <div className="image">
                                            <FaPlus />  </div>
                                        <span>Unlimited capabilities.</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Integration </td>
                                    <td>
                                        <div className="image">
                                            <FaPlusMinus /> </div>
                                        <span>API-enabled integration with popular software products.</span>
                                        <p>Integration with custom solutions and legacy systems may be complex or impossible.</p>
                                    </td>
                                    <td>
                                        <div className="image">
                                            <FaPlus />  </div>
                                        <span>Seamless connection to all required business systems, including legacy software.</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>User adoption </td>
                                    <td>
                                        <div className="image">
                                            <FaPlusMinus /> </div>
                                        <span>May be hampered due to complicated, inconvenient UX and UI.</span>
                                    </td>
                                    <td>
                                        <div className="image">
                                            <FaPlus /> </div>
                                        <span>High due to convenient UX and intuitive UI tailored to various user roles.</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Scalability and flexibility </td>
                                    <td>
                                        <div className="image">
                                            <FaPlusMinus /></div>
                                        <span>Restricted to the vendor’s platform capabilities.</span>
                                    </td>
                                    <td>
                                        <div className="image">
                                            <FaPlus /></div>
                                        <span>Non-restricted.</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Security </td>
                                    <td>
                                        <div className="image">
                                            <FaPlusMinus /></div>
                                        <span>Fully depends on the vendor’s cybersecurity practices and cannot be controlled by the customer.</span>
                                    </td>
                                    <td>
                                        <div className="image">
                                            <FaPlus /></div>
                                        <span>Minimized security risks due to custom code design and the ability to add advanced security features.</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Regulatory compliance  </td>
                                    <td>
                                        <div className="image">
                                            <FaPlusMinus /></div>
                                        <span>Typically limited to compliance with global data security standards.</span>
                                    </td>
                                    <td>
                                        <div className="image">
                                            <FaPlus /></div>
                                        <span>Compliance with all the required global, regional, and industry-specific regulations.</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Maintenance and support </td>
                                    <td>Performed by an OMS product vendor.</td>
                                    <td>Provided by an in-house team or an outsourced dedicated vendor.</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="forth db p50">
                    <div className="container">
                        <div className="inner-forth db">
                            <div className="heading">
                                <h2>Key Features of Service Operations Management Software</h2>
                                <p>Service operations management solutions may cater for businesses with both one-time projects and continuous service operations. They help streamline daily workflows for multiple user groups – operations and resource managers, project managers, service delivery teams, and others. Below, ScienceSoft shares a sample feature set of service operations management software.</p>
                            </div>
                            <div className="item-main50">
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <MdOutlineSettingsInputComposite />
                                        </div>
                                      <div className="line linec"><h5>Service planning and optimization (for managers)</h5></div>
                                        <ul>
                                            <li className="kadimadi white" >Setting up service-related workflows (approval workflows, corporate policies to follow, etc.).</li>
                                            <li className="kadimadi white" >Continuous service improvement management (identifying service best practices, gathering employees’ ideas and conducting votes on service improvement ideas).</li>
                                            <li className="kadimadi white" >Service level agreement management.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <PiProjectorScreenChart />
                                        </div>
                                        <div className="line linec"><h5>Project tasks and service requests management</h5></div>
                                        <ul>
                                            <li className="kadimadi white" >Task/request assignment and progress status tracking.</li>
                                            <li className="kadimadi white" >Automated booking of employees’ working hours based on assigned activities.</li>
                                            <li className="kadimadi white" >Configurable task/request schedule viewer for employees/teams (with a calendar view, Gantt chart view, etc.).</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <FaTasks />
                                        </div>
                                        <div className="line linec"><h5>Time and expense management</h5></div>
                                        <ul>
                                            <li className="kadimadi white" >Configurable timesheets and expense claim forms.</li>
                                            <li className="kadimadi white" >Time tracking of employees’ billable and non-billable hours.</li>
                                            <li className="kadimadi white" >Expense tracking (submitting receipts for transportation expenses, etc.).</li>
                                            <li className="kadimadi white" >Automated approval workflow for employees’ timesheets and expense claims.</li>
                                            <li className="kadimadi white" >Automated invoice generation (based on approved billable hours and expenses) and status tracking (approved/pending, etc.).</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <GrDocumentTime />
                                        </div>
                                        <div className="line linec"><h5>Document management</h5></div>
                                        <ul>
                                            <li className="kadimadi white" >Centralized storage of service-related documents (contracts, project plans, meeting notes, policies, etc.).</li>
                                            <li className="kadimadi white" >Search engine with document metadata querying.</li>
                                            <li className="kadimadi white" >Template-based document creation.</li>
                                            <li className="kadimadi white" >Auto-referencing of documents to related projects, tasks, etc.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <GiHumanPyramid />
                                        </div>
                                       <div className="line linec"> <h5>Document management</h5></div>
                                        <ul>
                                            <li className="kadimadi white" >Centralized view of human resource data (employee profiles with up-to-date information on skills, availability, workload, and bill rates).</li>
                                            <li className="kadimadi white" >Human resource allocation (comparing skill sets available in employee profiles and assigning fitting candidates to projects/service requests).</li>
                                            <li className="kadimadi white" >AI-driven suggestions on resource allocation.</li>
                                            <li className="kadimadi white" >Real-time resource demand forecasting (based on the analysis of available capacity).</li>
                                            <li className="kadimadi white" >Configurable capacity utilization dashboards (allow checking capacity per specific periods, business units, departments, etc.).</li>
                                            <li className="kadimadi white" >Alerting on resource under- or overutilization to resource managers and PMs.</li>
                                            <li className="kadimadi white" >AI-driven recommendations on where to engage under-utilized resources.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <GoProjectSymlink />
                                        </div>
                                        <div className="line linec"><h5>Project management</h5></div>
                                        <ul>
                                            <li className="kadimadi white" >Project planning (setting up goals, milestones, timeline, budget, stakeholders, etc.).</li>
                                            <li className="kadimadi white" >Project process breakdown (creating and editing tasks and sub-tasks required to complete a project).</li>
                                            <li className="kadimadi white" >Project time and resources estimation based on historical data about completed projects.</li>
                                            <li className="kadimadi white" >Form-based resource request creation and automated routing to resource managers.</li>
                                            <li className="kadimadi white" >Current vs. planned project progress monitoring.</li>
                                            <li className="kadimadi white" >Current vs. forecast project margin monitoring.</li>
                                            <li className="kadimadi white" >Project budget monitoring (burn rate, remaining budget, etc.).</li>
                                            <li className="kadimadi white" >Security and compliance monitoring (critical, e.g., for IT operations management software).</li>
                                            <li className="kadimadi white" >Configurable alerts on deadlines, project risks, etc. (for project managers and team members).</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <MdLocalGroceryStore />
                                        </div>
                                       <div className="line linec"> <h5>Supply management</h5></div>
                                        <ul>
                                            <li className="kadimadi white" >Barcode-based tracking of supplies’ quantity and location.</li>
                                            <li className="kadimadi white" >Setup of reorder levels and alerts for low-stock supplies (can be tuned for different locations).</li>
                                            <li className="kadimadi white" >Supply purchase order creation and approval.</li>
                                            <li className="kadimadi white" >Purchase order status tracking and history overview.</li>
                                            <li className="kadimadi white" >Setup of preferred suppliers for ordering.</li>
                                            <li className="kadimadi white" >Supply demand forecasting (based on historical demand data and seasonal demand fluctuations).</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <GrAnalytics />
                                        </div>
                                       <div className="line linec"> <h5>Project and service analytics</h5></div>
                                        <ul>
                                            <li className="kadimadi white" >Change impact analysis (shows how changes, e.g. unexpected delays, affect service schedule or project progress).</li>
                                            <li className="kadimadi white" >Revenue forecasting based on real-time monitoring of service/project delivery (can be drilled down by service types or projects, clients, business units, etc.).</li>
                                            <li className="kadimadi white" >Resource utilization reports.</li>
                                            <li className="kadimadi white" >AI-based project/service risk assessment (e.g., comparing customer demand with a company’s current resource capacity).</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="heading">
                                <h2>Important Integrations for Service Operations Management Software</h2>
                                <p>To streamline planning, control and analysis of service operations, ScienceSoft recommends that service OMS should be integrated with the following back-office systems:</p>
                                <div className="image"><img className="nohover" src="../assets/images/integrations-service-oms-.png" alt="" /></div>
                                <ul>
                                    <li className="kadimadi white" ><strong>OMS + analytics software: </strong>to reveal trends in resource availability and utilization, financial project health, etc. and optimize operations planning.</li>
                                    <li className="kadimadi white" ><strong>OMS + customer relationship management (CRM) software:</strong>for fast service request scheduling and assignment to employees; for streamlined creation of a project/task.</li>
                                    <li className="kadimadi white" ><strong>OMS + a customer portal: </strong>to speed up timesheet and expense approvals and keep customers up to date on the project progress; to timely initiate changes in a project.</li>
                                    <li className="kadimadi white" ><strong>OMS + accounting software:</strong>for streamlined customer billing, employee compensation and payroll management; for accurate revenue recording and recognition.</li>
                                </ul>
                            </div>
                            <div className="heading">
                                <h2>Key Features of Production Operations Management Software</h2>
                                <p>Having in-depth experience in building production operations management tools, ScienceSoft shares a sample feature set that forms the solution's core. However, each real-life use case for such software is unique, so functionality should be elaborated on and tailored to the business specifics accordingly.</p>
                            </div>
                            <div className="item-main50">
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <AiFillProduct />
                                        </div>
                                        <h5>Production planning</h5>
                                        <ul>
                                            <li className="kadimadi white" >Production KPIs setup and monitoring (e.g., production volume and costs).</li>
                                            <li className="kadimadi white" >Material requirements planning.</li>
                                            <li className="kadimadi white" >Production scheduling.</li>
                                            <li className="kadimadi white" >Automated production schedule adjustment (e.g., in case of incoming make-to-order requests or production delays).</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <PiNetworkDuotone />
                                        </div>
                                        <h5>Operations optimization</h5>
                                        <ul>
                                            <li className="kadimadi white" >AI suggestions for production optimization (based on historical production data).</li>
                                            <li className="kadimadi white" >Multi-plant production optimization (balancing production load across several plants).</li>
                                            <li className="kadimadi white" >What-if production scenario modeling and cross-comparison (to generate different options of a production schedule and choose the best one).</li>
                                            <li className="kadimadi white" >Multi-location inventory optimization (calculating optimal inventory levels across warehouses based on multiple factors, from sales data to weather conditions).</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <MdOutlineBorderColor />
                                        </div>
                                        <h5>Production order management</h5>
                                        <ul>
                                            <li className="kadimadi white" >Production order creation (with auto-attachment of BOMs, e.g., via an integration with PLM software).</li>
                                            <li className="kadimadi white" >Automated estimation of production order costs and capacity requirements (based on product specifications).</li>
                                            <li className="kadimadi white" >Automated reservation of materials for a production order.</li>
                                            <li className="kadimadi white" >Production order routing (defining the sequence of operations to produce a product, integrating an order into the production schedule) and status tracking.</li>
                                            <li className="kadimadi white" >Automated generation of a production order receipt (with the final calculation of production costs based on the recorded material, machine and workforce usage).</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <MdOutlineInventory />
                                        </div>
                                        <h5>Inventory management</h5>
                                        <ul>
                                            <li className="kadimadi white" >Inventory levels monitoring</li>
                                            <li className="kadimadi white" >Setup of inventory reorder points and automated trigger of purchase orders.</li>
                                            <li className="kadimadi white" >Alerts on low-level inventory.</li>
                                            <li className="kadimadi white" >Creation, routing and status tracking of inventory transfer orders.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <FaWarehouse />
                                        </div>
                                        <h5>Warehouse management</h5>
                                        <ul>
                                            <li className="kadimadi white" >Automated inventory location tracking (with barcode/RFID technology).</li>
                                            <li className="kadimadi white" >Expiration and shelf-life tracking (e.g., for raw materials).</li>
                                            <li className="kadimadi white" >AI-based inventory storage suggestions (optimal inventory location indicated based on its storage requirements, expiration date, etc.).</li>
                                            <li className="kadimadi white" >Order picking routing (the system suggests the quickest route to pick up an order for a warehouse worker).</li>
                                            <li className="kadimadi white" >AI-based packing suggestions (the system automatically defines the type and amount of packing required for an order).</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <MdOutlineBorderColor />
                                        </div>
                                        <h5>Sales order management</h5>
                                        <ul>
                                            <li className="kadimadi white" >Sales order creation and status tracking.</li>
                                            <li className="kadimadi white" >Automated invoice generation and routing for approval.</li>
                                            <li className="kadimadi white" >Automated generation of purchase and/or production orders from a sales order.</li>
                                            <li className="kadimadi white" >Automated inventory reservation for a sales order.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <MdAddShoppingCart />
                                        </div>
                                        <h5>Purchase order management</h5>
                                        <ul>
                                            <li className="kadimadi white" >Purchase order request creation and automated routing for approval.</li>
                                            <li className="kadimadi white" >Purchase order creation and status tracking.</li>
                                            <li className="kadimadi white" >Setup of preferred suppliers for particular inventory groups.</li>
                                            <li className="kadimadi white" >Setup of spend limits per user/department (on purchase orders).</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <MdWorkHistory />
                                        </div>
                                        <h5>Workforce management</h5>
                                        <ul>
                                            <li className="kadimadi white" >Workforce scheduling (production and maintenance workers, etc.).</li>
                                            <li className="kadimadi white" >Workforce performance reports.</li>
                                            <li className="kadimadi white" >Instant messaging (for easy communication between planners and operations managers and the shop-floor personnel and vice versa).</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <LuServerCog />
                                        </div>
                                        <h5>Machinery and equipment maintenance management</h5>
                                        <ul>
                                            <li className="kadimadi white" >Machine maintenance scheduling (for regular maintenance activities coordinated with a production schedule).</li>
                                            <li className="kadimadi white" >Maintenance request creation and automated routing for approval (for on-demand activities, e.g., in case of machine malfunction or irregular performance).</li>
                                            <li className="kadimadi white" >Maintenance order assignment and status tracking.</li>
                                            <li className="kadimadi white" >Predictive maintenance for equipment.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <GiNotebook />
                                        </div>
                                        <h5>Production quality control</h5>
                                        <ul>
                                            <li className="kadimadi white" >Quick form-based creation of quality control charts.</li>
                                            <li className="kadimadi white" >Scheduling and assignment of quality checks (with auto-attachment of quality control charts).</li>
                                            <li className="kadimadi white" >Alert escalation on defective products (to production supervisors).</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <FaChartPie />
                                        </div>
                                        <h5>Analytics and reporting</h5>
                                        <ul>
                                            <li className="kadimadi white" >Product demand forecasting.</li>
                                            <li className="kadimadi white" >Product demand vs. production capacity analysis.</li>
                                            <li className="kadimadi white" >Workforce demand forecasting.</li>
                                            <li className="kadimadi white" >Impact analysis for production schedule adjustments (to see how current production orders will be affected by the changes).</li>
                                            <li className="kadimadi white" >Capacity utilization reports (on machine and workforce utilization).</li>
                                            <li className="kadimadi white" >Production reports (on the production outputs at a given period, e.g. day/week).</li>
                                            <li className="kadimadi white" >Production costs monitoring and analysis.</li>
                                            <li className="kadimadi white" >Inventory carrying costs analysis.</li>
                                            <li className="kadimadi white" >Inventory purchasing reports (with historical costs trends).</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="heading">
                                <h2>Operations Management Software Implementation with ScienceSoft</h2>
                                <p>With 35 years in custom software development for 30+ industries, ScienceSoft knows how to design and implement functionally rich and high-performing operations software for streamlined workflows of any complexity.</p>
                            </div>
                            <div className="item-main50">

                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <img className="nohover" src="../assets/images/software-implementation1.png" alt="" />
                                        </div>
                                        <h5>OMS consulting</h5>
                                        <p>We design a proper architecture and a feature set to meet your unique operational management needs, and introduce a best-fitting tech stack for OMS implementation. You also get a detailed project roadmap, a risk mitigation plan, and expert advice on cost optimization opportunities.</p>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <img className="nohover"  src="../assets/images/software-implementation.png" alt="" />
                                        </div>
                                        <h5>
                                            OMS development
                                        </h5>
                                        <p>We fully develop your OMS and integrate the solution with the relevant corporate systems. Having 750+ experienced IT talents on board, we ensure fast implementation and top quality of your OMS. We’re ready to carry out comprehensive user training to streamline OMS adoption and provide on-demand OMS support and evolution</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Operation_Consulting;