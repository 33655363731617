import React from "react";
import Header from "./Header";
const TC = () => {
    return (
        <>
            <div className="termsC">
                <div className="container">
        <Header/>
                    <div className="inner-tc">
                        <div className="heading">
                            <h1>Terms & Conditions</h1>
                        </div>
                        <p>Welcome to Sprier Technology Consultancy. By accessing or using our services, you agree to comply with and be bound by the following terms and conditions. Please review them carefully.</p><br />
                        <ul>
                            <li>"Company" refers to Sprier Technology Consultancy, its employees, and affiliates. </li><br />
                            <li>"Client" refers to the individual or entity that enters into a contract with the Company.</li><br />
                            <li>"Services" refers to the IT services provided by the Company, including but not limited to software development, maintenance, support, consulting, and other related services. </li><br />
                        </ul>
                        <h4>Scope of Services</h4>
                        <p>The Company agrees to provide the Services as specified in the Statement of Work (SOW) or the service agreement entered into with the Client. Any changes or additional services will require a written amendment to the agreement.</p>
                        <h4>Payment Terms</h4>
                        <ul>
                            <li> Payment Terms: <br /> <br />
                            All payments for services provided by Sprier Technology Consultancy are due at the time of purchase or as agreed upon in the service agreement. The total payment amount may be split into two parts: a non-refundable 50% deposit and a refundable 50% balance. </li> <br /><br />
                            <li> Non-Refundable Deposit: <br /><br />
                            A 50% deposit is required upfront to secure the commencement of work on your project. This deposit covers preliminary research, project planning, and initial development efforts. The deposit is non-refundable under any circumstances, including but not limited to project cancellation by the client, change of mind, or project delays caused by the client.</li><br /><br />
                            <li>Refundable Balance: <br /><br />
                            The remaining 50% of the payment is due upon completion of the project or as specified in the service agreement. This portion is refundable only if Sprier Technology Consultancy fails to deliver the services as agreed upon</li><br /><br />
                            <li>For any questions or concerns regarding our payment and refund policy, please contact us at <a href="mailto:info@spirertechnology.com">info@spirertechnology.com</a> / <a href="tel:+91 96019 99151">+91 96019 99151</a></li>
                            
                        </ul>
                        <h4>Confidentiality</h4>
                        <p>Both parties agree to maintain the confidentiality of any proprietary or confidential information disclosed during the course of the agreement. This obligation will continue for [number] years after the termination of the agreement.</p>
                        <h4>Intellectual Property</h4>
                        <ul>
                            <li>Ownership: The Company retains ownership of all intellectual property rights in any materials, methodologies, processes, or tools used or developed during the course of providing the Services. </li><br />
                            <li>License: The Company grants the Client a non-exclusive, non-transferable license to use any deliverables provided under the agreement for their intended purpose.</li><br />
                        </ul>
                        <h4>Warranties and Disclaimers</h4>
                        <ul>
                            <li>Warranty: The Company warrants that the Services will be performed in a professional and workmanlike manner. </li><br />
                            <li>Disclaimer: Except as expressly provided in these terms, the Services are provided "as is" and the Company disclaims all other warranties, express or implied.</li><br />
                        </ul>
                        <h4>Limitation of Liability</h4>
                        <p>To the fullest extent permitted by law, the Company’s total liability to the Client for any claims arising out of or relating to the agreement will be limited to the amount paid by the Client for the Services. The Company will not be liable for any indirect, incidental, special, or consequential damages.</p>
                        <h4>Termination</h4>
                        <ul>
                            <li>For Convenience: Either party may terminate the agreement by providing [number] days’ written notice to the other party. </li><br />
                            <li>For Cause: Either party may terminate the agreement immediately if the other party breaches any material term of the agreement and fails to cure the breach within [number] days of receiving notice. </li><br />
                        </ul>
                        <h4>Governing Law</h4>
                        <p>This agreement will be governed by and construed in accordance with the laws of India. Any disputes arising out of or in connection with this agreement will be subject to the exclusive jurisdiction of the courts in Surat, Gujarat.</p>
                        <h4>Force Majeure</h4>
                        <p>The Company will not be liable for any failure to perform its obligations under the agreement if such failure results from circumstances beyond the Company’s reasonable control, including but not limited to acts of God, war, terrorism, labor disputes, or government actions.</p>
                        <h4>Amendments</h4>
                        <p>Any amendments or modifications to this agreement must be in writing and signed by authorized representatives of both parties.</p>
                        <h4>Entire Agreement</h4>
                        <p>This agreement constitutes the entire agreement between the parties and supersedes all prior or contemporaneous agreements, understandings, or representations, whether oral or written. </p>
                        <h4>Contact Information</h4>
                        <p>For any questions or concerns regarding these terms and conditions, please contact us at: </p><br />
                        <p><a href="mailto:info@spirertechnology.com">info@spirertechnology.com</a></p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TC;