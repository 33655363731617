import React from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
const Service = () => {
    return (
        <>
            <div className="service-main">
                <div className="service-inner backgroundimage">
                    <Header />
                    <div class="main db p50">
                        <div class="container">
                            <div class="inner-main">
                                <div class="item">
                                    <div class="item-inner">
                                        <h4 className="tc" >EXPERTISE</h4>
                                        <div class="contex">
                                            <ul>
                                                <Link to="/Cloud Computing"><li className="white kadimadi">Cloud Computing</li></Link>
                                                <Link to="/Custom_Software_Development"><li className="white kadimadi"> Software Development</li></Link>
                                                <Link to="/Website_Devlopment_designing"><li className="white kadimadi">Website Development and Designing</li></Link>
                                                <Link to="/Application_Development_Designing"><li className="white kadimadi">Application Development and Designing</li></Link>
                                                <Link to="/UIUX"><li className="white kadimadi">UI/UX</li></Link>
                                                <Link to="/SaaS"><li className="white kadimadi">SaaS Development</li></Link>
                                                <Link to="/CRM Consulting"><li className="white kadimadi">CRM Consulting</li></Link>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="item-inner">
                                        <h4 className="tc" >CONSULTING</h4>
                                        <div class="contex">
                                            <ul>
                                                <Link to="/Technology Enabled Business Transformation"><li className="white kadimadi">Technology Enabled Business Transformation</li></Link>
                                                <Link to ="/Innovation Consulting"><li className="white kadimadi">Innovation Consulting</li></Link>
                                                <Link to ="/Operation Consulting"><li className="white kadimadi">Operation Consulting</li></Link>
                                                <Link to ="/E Commerce"><li className="white kadimadi">E-Commerce</li></Link>
                                                <Link to ="/Fintech"><li className="white kadimadi">Fintech</li></Link>
                                                <Link to ="/Manufacturing Tracking Software"><li className="white kadimadi">Manufacturing Tracking Software</li></Link>
                                                <Link to ="/Health Care"><li className="white kadimadi">Health Care</li></Link>
                                                <Link to ="/Education & E-Learning"><li className="white kadimadi">Education & E-Learning</li></Link>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer/> */}
        </>
    );
}
export default Service;