import React from "react";
import HMain from "./HMain";
import AboutUs from "./Aboutus";
import Precption from "./Preceprion";
import JoinUs from "./JoinUs";
import Header from "./Header";
import Technologies2 from "./Technologies2";
import ScrollToTop from "react-scroll-to-top";
import Footer from './Footer';
const Home = () => {
    return (
        <>
            <div className="home-main">
               
                <HMain />
                {/* <AboutUs /> */}
                <Technologies2/>
                <Precption />
                <JoinUs/>
                {/* <Footer /> */}
            </div>
        </>
    );
}
export default Home;