// import React from "react";
// import { FaInstagram } from "react-icons/fa";
// import { FaLinkedinIn } from "react-icons/fa6";
// import { FaFacebookF } from "react-icons/fa";
// import { IoMdContact } from "react-icons/io";
// import { Link } from "react-router-dom";
// const Footer = () => {

//     return (
//         <>
//             <div className="footer-main">
//                 <div className="container">
//                     <div className="inner-footer">
//                         <div className="project">
//                             <div className="project-inner">
//                                 Want to <br /> Start a Project?
//                             </div>
//                             <div className="talk">
//                                 <div className="logo">
//                                     <Link to="/" > <img src="assets/images/logo three mod 100px stroke.png" alt="" /> </Link>
//                                 </div>
//                                 <div className="right">
//                                     <div className="right-inner">
//                                         <div className="item">
//                                             <div className="item-inner">
//                                                 <ul>
//                                                     <li>Support</li>
//                                                     <p><Link to="/" >Home</Link></p>
//                                                     <p><Link to="/About" >About</Link></p>
//                                                     <p><Link to="/Service" >Service</Link></p>


//                                                 </ul>
//                                             </div>
//                                         </div>
//                                         <div className="item">
//                                             <div className="item-inner">
//                                                 <ul>
//                                                     <li>Others</li>
//                                                     <p><Link to="/PrivacyPolicy" >Privacy Policy</Link></p>
//                                                     <p><Link to="/Terms and Conditions" >Terms and Conditions</Link></p>
//                                                 </ul>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="mail">
//                                     <a href="">info@spirertechnology.com</a>
//                                 </div>
//                             </div>
//                             <div className="social-media">
//                             <a className="instagram" href="https://www.instagram.com/_sprier_/" target="_blank" ><FaInstagram /></a>    
//                             <a className="facebook" href="https://www.facebook.com/people/Sprier-Technology-Consultancy/61560621571425/?mibextid=LQQJ4d&rdid=9dztpeaac9jQwm7b&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FLHToXBorDcCsA5Ev%2F%3Fmibextid%3DLQQJ4d" target="_blank" > <FaFacebookF /></a>    
//                             <a className="linkdn" href="https://www.linkedin.com/in/sprier-technology-01716731a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app " target="_blank" ><FaLinkedinIn /></a>    
//                             <Link to="/Contact" className="contact_icon" target="_blank" > <IoMdContact /></Link>    
                                
                               
                               
//                                 <div className="copyright">
//                                     <span>2024 © All Rights Reserved By Sprier Technology Consultancy.</span>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }
// export default Footer;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { IoMdContact } from "react-icons/io";
import { Link } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Footer = () => {
  return (
    <>
      <ScrollToTop />
      <div className="footer-main p50">
        <div className="container">
          <div className="inner-footer">
            <div className="project">
              <div className="project-inner">
                Want to <br /> Start a Project?
              </div>
              <div className="talk">
                <div className="logo">
                  <Link to="/">
                    <img className="bn logo" src="assets/images/logo three mod 100px stroke.png" alt="" />
                  </Link>
                </div>
                <div className="right">
                  <div className="right-inner">
                    <div className="item">
                      <div className="item-inner">
                        <ul>
                          <li>Support</li>
                          <p><Link to="/">Home</Link></p>
                          <p><Link to="/About">About</Link></p>
                          <p><Link to="/Service">Service</Link></p>
                        </ul>
                      </div>
                    </div>
                    <div className="item">
                      <div className="item-inner">
                        <ul>
                          <li>Others</li>
                          <p><Link to="/PrivacyPolicy">Privacy Policy</Link></p>
                          <p><Link to="/Terms and Conditions">Terms and Conditions</Link></p>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mail">
                  <a href="mailto:info@spirertechnology.com">info@spirertechnology.com</a>
                </div>
              </div>
              <div className="social-media">
                <a className="instagram" target="_blank" rel="noreferrer" href="https://www.instagram.com/_sprier_/"><FaInstagram /></a>
                <a className="facebook" target="_blank" rel="noreferrer"  href="https://www.facebook.com/people/Sprier-Technology-Consultancy/61560621571425/?mibextid=LQQJ4d&rdid=9dztpeaac9jQwm7b&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FLHToXBorDcCsA5Ev%2F%3Fmibextid%3DLQQJ4d" ><FaFacebookF /></a>
                <a className="linkdn" target="_blank" rel="noreferrer"  href="https://www.linkedin.com/in/sprier-technology-01716731a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"><FaLinkedinIn /></a>
                <Link to="/Contact" className="contact_icon" target="_blank"><IoMdContact /></Link>
                <div className="copyright">
                  <span>2024 © All Rights Reserved By Sprier Technology Consultancy.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
