import React, { useState } from "react";
import { Button, Drawer } from "antd";
import { IoMenu } from "react-icons/io5";

import { Link } from "react-router-dom";
const Header = () => {
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    return (
        <>
            {/* main header start  */}
            <div class="header-main" id="main-header">
                <div class="container">
                    <div class="inner-header">
                        <div class="logo">
                            <div class="image">

                                <Link to="/"> <img className="bn" src="assets/images/logo three mod 100px stroke.png" alt="" /></Link>

                            </div>
                        </div>
                        <div class="nev">
                            <ul class="nav-list">
                                <li class="nav-item">
                                    <Link to="/" class="nav-link">Home
                                    </Link>
                                </li>
                                {/* <li class="nav-item">
                                    <Link to="/" class="nav-link">Product
                                    </Link>
                                </li> */}
                                <li class="nav-item">
                                    <Link to="/Service" class="nav-link">Service
                                    </Link>
                                </li>

                                <li class="nav-item">
                                    <Link to="/Technologies" class="nav-link">Technologies
                                    </Link>
                                </li>
                                <li class="nav-item">
                                    <Link to="/About" class="nav-link">About us
                                    </Link>
                                </li>
                                <li class="nav-item">
                                    <Link to="/Carrer" class="nav-link">Career
                                    </Link>
                                </li>
                                <li class="nav-item">
                                    <Link to="/Hire Us" class="nav-link">Hire Us
                                    </Link>
                                </li>
                                <li class="nav-item">
                                    <Link to="/Contact" class="nav-link">Contact us
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* main header end  */}
            {/* main header start  */}
            <div className="main-head">
                <div className="container">
                    <div className="main-inner">
                        <div className="item-head">
                            <div className="inner">
                                <div className="my-info">
                                    <div class="logo">
                                        <div class="image">

                                            <Link to="/"> <img className="bn" src="assets/images/logo three mod 100px stroke.png" alt="" /></Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item-head">
                            <div className="inner">
                                <div className="bar-1">
                                    <Button type="primary" onClick={showDrawer}>
                                        <span className="bar"><IoMenu /></span>
                                    </Button>
                                    <Drawer title="Basic Drawer" onClose={onClose} open={open}>
                                        <p><Link to="/" className="active">Home</Link></p>
                                        {/* <p><Link to="/">Product</Link></p> */}
                                        <p><Link to="/Service">Service</Link></p>
                                        <p><Link to="/Technologies">Technologies</Link></p>
                                        <p> <Link to="/About">About us</Link></p>
                                        <p><Link to="/Carrer">Career</Link></p>
                                        <p><Link to="/Hire Us">Hire Us</Link></p>
                                        <p><Link to="/Contact">Contact</Link></p>
                                    </Drawer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* main header end */}

        </>
    );
}
export default Header;