

import React from "react";
import Header from "../Header";
import { TiArrowRepeatOutline, TiDeviceLaptop } from "react-icons/ti";
import { PiArrowsSplitBold, PiDevicesDuotone } from "react-icons/pi";
import { IoMdSettings } from "react-icons/io";
import { MdOutlineSettingsInputComponent } from "react-icons/md";
import { LuArrowLeftRight, LuListTodo, LuSearchCode } from "react-icons/lu";
import { FaScrewdriverWrench } from "react-icons/fa6";
import { AiTwotoneSound } from "react-icons/ai";
import { FaHeadset, FaLaptopCode, FaVirus } from "react-icons/fa";
const Crm = () => {
    return (
        <>
            <div className="crm_main db">
                <div className="crm_inner backgroundimage">
                    <Header />
                    <div className="container">
                        <div className="main-heading">
                            <div className="heading-main crm_c"><h1>
                                CRM Consulting Company
                            </h1></div>
                            <div className="heading-main crm_c"><p>Level up your customer strategy with our CRM consulting company.</p><ul>
                                <li className="kadimadi white white" >Boost sales by 29%</li>
                                <li className="kadimadi white white" >Amplify productivity by 34%</li>
                                <li className="kadimadi white white" >Enhance forecast accuracy by 42%</li></ul></div>
                        </div>
                    </div>
                </div>
                <div className="second db ">
                    <div className="container">
                        <div className="inner-second db">
                            <div className="item-main50">
                                <div className="item50">
                                    <div className="item-inner50">
                                        <h3>Opt for your CRM implementation solution</h3>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <p>Put your customers first and ensure your organization works together to meet their needs.</p>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image"><TiArrowRepeatOutline /></div>
                                        <div className="line">
                                            <h5>B2B CRM</h5>
                                        </div>
                                        <p>Our CRM systems help you overcome channel complexity, operate across a range of markets, and get through to the right person – fast, easy, and within a single interface. Connect with vendor partners, resellers, or affiliate companies via an automated, easily trackable workflow.</p>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image"><PiArrowsSplitBold /></div>
                                        <div className="line">
                                            <h5>B2C CRM</h5>
                                        </div>
                                        <p>Keep track of every opportunity and lead, increase the average value of sales through personalized experiences, and improve your marketing and customer retention strategies. Our feature-packed CRM software delivers ultimate visibility across your sales pipeline for more leads and maximized revenues.</p>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <h3>Covering all aspects of your CRM project</h3>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <p>We know what it takes to win big with customers. Our CRM consultancy team will take the time to look into your business objectives and develop the best solution for your needs. From identifying the right tools for your business to optimizing your CRM platform performance, our team is here to help at every step of your adoption journey.</p>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image"><IoMdSettings /></div>
                                        <div className="line">
                                            <h5>CRM setup</h5>
                                        </div>
                                        <p>We make sure your system is off to a good start and is properly customized to drive every business process from top to bottom. Our experts will set up the organizational hierarchy, establish data visibility rules, and sort out email integration and lead generation processes to get you started on the right foot.</p>
                                        <ul>
                                            <li className="kadimadi white" >General system setup</li>
                                            <li className="kadimadi white" >CRM integration</li>
                                            <li className="kadimadi white" >Team access and permissions</li>
                                            <li className="kadimadi white" >Sales and marketing automation</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image"><MdOutlineSettingsInputComponent /></div>
                                        <div className="line">
                                            <h5>CRM customization</h5>
                                        </div>
                                        <p>Our consulting CRM team configures your CRM platform to make it a perfect fit for your unique business tasks. Our developers build  solutions, modules, and plug-ins on top of existing technologies like Odoo, Hubspot, Zoho, and other canned solutions, to minimize the time for critical tasks and to boost your productivity.</p>
                                        <ul>
                                            <li className="kadimadi white" > pipelines and business flows</li>
                                            <li className="kadimadi white" > CRM extensions</li>
                                            <li className="kadimadi white" > reports and dashboard widgets</li>
                                            <li className="kadimadi white" >Third-party APIs and integrations</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image"><LuArrowLeftRight /></div>
                                        <div className="line">
                                            <h5>Data migration</h5>
                                        </div>
                                        <p>We help you switch from legacy systems, migrate data from other business systems, or shift to the cloud. Our data engineers analyze your data requirements, prepare input for migration, and create backups so that you have a single source of truth based on accurate and complete insights.</p>
                                        <ul>
                                            <li className="kadimadi white" >Cloud migration</li>
                                            <li className="kadimadi white" >Data consolidation</li>
                                            <li className="kadimadi white" >Data preparation and cleaning</li>
                                            <li className="kadimadi white" >Migrating data from other CRMs</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image"><FaScrewdriverWrench /></div>
                                        <div className="line">
                                            <h5>CRM maintenance and update</h5>
                                        </div>
                                        <p>Our CRM software consulting unit keeps your solution at its peak performance, eliminates performance flaws, and updates the platform to match your evolving needs. We offer a full cycle of maintenance activities, including troubleshooting, data quality review, performance audits, and more.</p>
                                        <ul>
                                            <li className="kadimadi white" >L1-L3 support services</li>
                                            <li className="kadimadi white" >User training and documentation</li>
                                            <li className="kadimadi white" >CRM data maintenance</li>
                                            <li className="kadimadi white" >Feature update</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="third db p50">
                    <div className="container">
                        <div className="inner-third">
                            <div className="heading">
                                <h3>CRM third-party system integrations</h3>
                                <p>
                                    Connect your CRM services with new and existing business tools so you can spend more of your time on things that matter.
                                </p>
                            </div>
                            <div className="third-inner db">
                                <div className="item3">
                                    <div className="left"><AiTwotoneSound /></div>
                                    <div className="center">
                                        <h5>
                                            Marketing automation tools
                                        </h5>
                                    </div>
                                    <div className="right"><p>Our developers synchronize world-class marketing automation tools with your CRM system to increase the effectiveness of your marketing stack, eliminate data silos, and improve lead nurturing. The powerful synergy of CRM and marketing software adds to more accurate customer data, amplifies personalized communication, and boosts the performance of your sales force.</p></div>
                                </div>
                                <div className="item3">
                                    <div className="left"><FaLaptopCode /></div>
                                    <div className="center">
                                        <h5>

                                            Business Intelligence software

                                        </h5>
                                    </div>
                                    <div className="right"><p>Keep your CRM data in sync with BI tools to gain gold-level insights into buyer behavior, supercharge decision-making, and unearth hidden patterns residing in your customer management software. Our BI team creates a goal-driven, real-time visual interface that gives a thorough understanding of the main variables and opportunities for your teams.</p></div>
                                </div>
                                <div className="item3">
                                    <div className="left"><LuListTodo /></div>
                                    <div className="center">
                                        <h5>
                                            Enterprise resource planning (ERP)
                                        </h5>
                                    </div>
                                    <div className="right"><p>Achieve a unified, 360-degree view of your business processes, whether it’s sales figures, inventory levels, or budget planning. By connecting your ERP with the CRM system, you speed up accounting cycles, reduce the TAT of sales transactions, automate your quote-to-cash processes, and succeed in demand-driven budgeting.</p></div>
                                </div>
                                <div className="item3">
                                    <div className="left"><FaVirus /></div>
                                    <div className="center">
                                        <h5>

                                            Document management systems (DMS)

                                        </h5>
                                    </div>
                                    <div className="right"><p>We help you set up a centralized library for all of your sales papers to facilitate document circulation and eliminate the burden of contract tracking. Access, create, and edit all of your files directly from the CRM system to seal the deal faster and reduce the time spent on document creation.</p></div>
                                </div>
                                <div className="item3">
                                    <div className="left"><LuSearchCode /></div>
                                    <div className="center">
                                        <h5>

                                            Accounting systems

                                        </h5>
                                    </div>
                                    <div className="right"><p>Keep your contacts, products, and invoices synchronized across both systems to keep all your departments on the same side. By combining accounting packages and CRM software, you get an end-to-end sales system with automated invoice management, integrated payments, and easier revenue tracking.</p></div>
                                </div>
                                <div className="item3">
                                    <div className="left"><FaHeadset /></div>
                                    <div className="center">
                                        <h5>

                                            Customer support and ticketing tools

                                        </h5>
                                    </div>
                                    <div className="right"><p>Enrich your CRM suite with the simplicity and feature set of a helpdesk app and elevate customer support with no back-and-forth of switching between the systems. Take the best of the two worlds to automate ticketing, provide meaningful interactions to your customers, and improve time-to-resolution within a single system.</p></div>
                                </div>
                                <div className="item3">
                                    <div className="left"><TiDeviceLaptop /></div>
                                    <div className="center">
                                        <h5>
                                            E-commerce platforms
                                        </h5>
                                    </div>
                                    <div className="right"><p>Our CRM e-commerce integration offers retailers a unified space to track customer interactions, generate more critical data for behavior analysis, and fine-tune portal content for each customer. Import product information, transfer orders straight from your account, and update your stock levels in real time for the ultimate customer experience.</p></div>
                                </div>
                                <div className="item3">
                                    <div className="left"><PiDevicesDuotone /></div>
                                    <div className="center">
                                        <h5>
                                            Website
                                        </h5>
                                    </div>
                                    <div className="right"><p>
                                        Improve your bottom line by spending less effort on admin tasks, shortening the sales cycle, and achieving better customer profiling. Our developers bridge the gap between your website and CRM suite to unlock granular analytics, standardize communication, and set automatic algorithms for processing incoming requests.
                                    </p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Crm;