import React, { useRef } from "react";
import Header from "./Header";
import emailjs from '@emailjs/browser'
import { object, string } from "yup";
import { useFormik } from "formik";
import Input from "./UI/Input/Input";
const Carrer = () => {
    const form = useRef();

    let contactSchema = object({
        user_name: string().required("*Please Enter Your Name"),
        user_mail: string()
            .required("*Please Enter Your Email")
            .email("*Please Enter Valid Email"),
        user_education: string().required("Please Enter Your Education"),
        user_college: string().required("Please Enter Your College"),
        user_bod:string().required("Please Enter Your Birth Of Date "),
        user_country:string().required("Please Enter Your Country"),
        // Removed required condition for message

    });
    const formik = useFormik({
        initialValues: {
            user_name: "",
            user_mail: "",
            user_education: "",
            user_college: "",
            user_country:"",
            user_bod:"",
            user_msg:"",

        },
        validationSchema: contactSchema,
        onSubmit: (values) => {
            const formElement = form.current;
            emailjs
                .sendForm('service_2yj7djm', 'template_r8s1hsr', formElement, {
                    publicKey: '1fv3ecFC6uWzytcem',
                })
                .then(
                    () => {
                        console.log('SUCCESS!');
                        formElement.reset();
                        formik.resetForm();
                    },
                    (error) => {
                        console.log('FAILED...', error.text);
                    },
                );
        },
    });

    const { handleChange, handleSubmit, handleBlur, values, errors, touched } = formik;

    return (
        <>
            {/* <video src="assets\video\backCarrer.mp4" autoPlay muted loop> */}
            <div className="home-main">
                <div className="carrer">
                    <Header />
                    <div className="container">

                        <div className="inner-carrer">
                            <div className="left">
                                <div class="eye"></div>

                                <div class="circle-1"></div>
                                <div class="circle-2"></div>
                                <div class="circle-3"></div>
                                <div class="circle-4"></div>
                                <div class="circle-5"></div>
                                <div class="circle-6"></div>
                                <div class="circle-7"></div>
                                <div class="circle-8"></div>
                                <div class="circle-9"></div>
                                <div class="circle-10"></div>
                                <div class="circle-11"></div>
                                <div class="circle-12"></div>
                                <div class="circle-13"></div>
                                <div class="circle-14"></div>

                                <div class="glitch"></div>

                                <div class="fragment-1"></div>
                                <div class="fragment-2"></div>
                                <div class="fragment-3"></div>


                                <svg width="190" height="190" viewBox="0 0 190 190" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <clipPath id='bagel1'>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M95 190C147.467 190 190 147.467 190 95C190 42.533 147.467 0 95 0C42.533 0 0 42.533 0 95C0 147.467 42.533 190 95 190ZM95 120C108.807 120 120 108.807 120 95C120 81.1929 108.807 70 95 70C81.1929 70 70 81.1929 70 95C70 108.807 81.1929 120 95 120Z" />
                                        </clipPath>
                                        <clipPath id='bagel2'>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M71 142C110.212 142 142 110.212 142 71C142 31.7878 110.212 0 71 0C31.7878 0 0 31.7878 0 71C0 110.212 31.7878 142 71 142ZM71 139C108.555 139 139 108.555 139 71C139 33.4446 108.555 3 71 3C33.4446 3 3 33.4446 3 71C3 108.555 33.4446 139 71 139Z" />
                                        </clipPath>
                                        <clipPath id='bagel3'>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M60 120C93.1372 120 120 93.1372 120 60C120 26.8628 93.1372 0 60 0C26.8628 0 0 26.8628 0 60C0 93.1372 26.8628 120 60 120ZM60 115C90.3757 115 115 90.3757 115 60C115 29.6243 90.3757 5 60 5C29.6243 5 5 29.6243 5 60C5 90.3757 29.6243 115 60 115Z" />
                                        </clipPath>
                                        <clipPath id='bagel4'>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M38 76C58.9868 76 76 58.9868 76 38C76 17.0132 58.9868 0 38 0C17.0132 0 0 17.0132 0 38C0 58.9868 17.0132 76 38 76ZM38 72C56.7777 72 72 56.7776 72 38C72 19.2224 56.7777 4 38 4C19.2223 4 4 19.2224 4 38C4 56.7776 19.2223 72 38 72Z" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className="right">
                                <div className="wrapper-main">
                                    <div className="wrapper">
                                        <form action="/Uplode" method="POST" className="form-data" ref={form} id="myForm" encType="multipart/form-data" onSubmit={handleSubmit} >
                                            <h2>CAREER</h2>
                                            <table>
                                                <div className="carrer-main">
                                                    <div className="carrer-inner">
                                                        <tr className="name">
                                                            <td>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="Enter Your Name"
                                                                    name="user_name"
                                                                    onChange={handleChange}
                                                                    value={values.user_name}
                                                                    onBlur={handleBlur}


                                                                />
                                                                <span className="error"  style={{ color: "red", marginTop: "-10px" }}>
                                                                    {touched.user_name && errors.user_name ? errors.user_name : ''}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Input
                                                                    type="email"
                                                                    name="user_mail"
                                                                    placeholder="Enter Your Email"
                                                                    className="abc"
                                                                    onChange={handleChange}
                                                                    value={values.user_mail}
                                                                    onBlur={handleBlur}
                                                                />
                                                                <span className="error"  style={{ color: "red", marginTop: "-10px" }}>
                                                                    {touched.user_mail && errors.user_mail ? errors.user_mail : ''}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="name">
                                                            <td>
                                                                <Input
                                                                    type="text"
                                                                    name="user_education"
                                                                    placeholder="Enter Your Education"
                                                                    onChange={handleChange}
                                                                    value={values.user_education}
                                                                    onBlur={handleBlur}
                                                                />
                                                                <span className="error"  style={{ color: "red", marginTop: "-10px" }}>
                                                                    {touched.user_education && errors.user_education ? errors.user_education : ''}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Input
                                                                    type="text"
                                                                    name="user_college"
                                                                    placeholder="Enter Your College"
                                                                    className="abc"
                                                                    onChange={handleChange}
                                                                    value={values.user_college}
                                                                    onBlur={handleBlur}
                                                                />
                                                                <span className="error"  style={{ color: "red", marginTop: "-10px" }}>
                                                                    {touched.user_college && errors.user_college ? errors.user_college : ''}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="name">
                                                            <td>
                                                                <Input
                                                                    type="text"
                                                                    name="user_country"
                                                                    placeholder="Enter Your Country"
                                                                    onChange={handleChange}
                                                                    value={values.user_country}
                                                                    onBlur={handleBlur}
                                                                />
                                                                <span className="error"  style={{ color: "red", marginTop: "-10px" }}>
                                                                    {touched.user_country && errors.user_country ? errors.user_country : ''}
                                                                </span>

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <Input
                                                                type="Date"
                                                                id="Date of Birth"
                                                                name="user_bod"
                                                                className="abc"
                                                                placeholder="Enter your Date of Birth"
                                                                onChange={handleChange}
                                                                value={values.user_bod}
                                                                onBlur={handleBlur}
                                                            />
                                                            <span className="error" style={{ color: "red", marginTop: "-10px" }}>
                                                                {touched.user_bod && errors.user_bod ? errors.user_bod : ''}
                                                            </span>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <textarea
                                                                    name="user_msg"
                                                                    id=""
                                                                    cols="50"
                                                                    rows="7"
                                                                    placeholder="Your Messge"
                                                                    onChange={handleChange}
                                                                    value={values.user_msg}
                                                                    onBlur={handleBlur}
                                                                >

                                                                </textarea>
                                                            </td>
                                                        </tr>
                                                        <tr></tr>
                                                        <tr><td></td></tr>
                                                        <button type="submit" className="submitb" id="submit">Submit</button>
                                                    </div>
                                                </div>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            {/* <Footer/> */}
        </>
    );
}
export default Carrer;