import React from "react";
import Header from "../Header";

const Tebt = () => {
    return (
        <>
            <div className="tebt_main db">
                <div className="tebt_inner backgroundimage">
                    <Header />
                    <div className="container">
                        <div className="main-heading">
                            <div className="heading-main tebt_mainc"><h1>Technology Enabled Business Transformation</h1></div>
                            <div className="heading-main tebt_mainc"><p><b> X2</b> more revenue is generated by companies leading with tech-enabled transformation than by those who are less digitally inclined. However, only 20% of digital transformations succeed in building significant value. We ensure our clients will make it into this success cluster.</p></div>
                        </div>
                    </div>
                </div>
                <div className="second db p50">
                    <div className="container">
                        <div className="inner-second db">
                            <div className="heading">
                                <h2>Empowered by technology. Driven by business</h2>
                            </div>
                            <div className="item-main50">
                                <div className="item50">
                                    <div className="item-inner50">
                                        <p>Organizations embarking on transformational projects often buy into the idea of some magical piece of technology that will make their problems vanish into thin air. This, unfortunately, isn’t a reality. Without a change in business processes, well-trained personnel, and confident leadership, tech initiatives will hardly get off the ground. On the other hand, the value of new tech tools’ implementation should not be underestimated. It’s almost impossible to transform a company’s business operations in an administrative way only, without implementing any proper and effective tools for improved operations.</p>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <p>Let’s see what can be done about it. Welcome to the concept of Technology-Enabled Business Transformation. What does it mean? It’s a complex service at the intersection of Business and IT consulting, providing an integrated solution.</p>
                                        <p>Our consultants, each having 15+ years in Technology and Operations, analyze the current state of your operations, propose the end-state solutions based on your vision, and define the gaps. We do not just implement a new geeky IT system. We do not just create fancy reports and presentations with a piece of advice. Instinctools reinvents the processes to make them truly efficient and implements cutting-edge tools to power your business into the future while bringing value today. </p>
                                    </div>
                                </div>
                                <div className="image">
                                    <div className="container">

                                        <img src="../assets/images/technology-enabled-business-transformation-image1-updated.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="heading db tc">
                                <h2>Stable and predictable product and platform strategy</h2>
                            </div>
                            <div className="item-main50">
                                <div className="container">
                                <p>You have plans for where you want to be in terms of sales volume and market position for the next five years, don’t you? You should have the same for your tech infrastructure. The ‘we’ll see’ approach doesn’t work. Acting on a whim is fraught with painful consequences.</p><br />
                                <p>Eventually, you may find yourself in a situation where your current system won’t be able to handle your rapid growth. Remember, the Titanic could have been saved before the collision with the iceberg, and not when the vessel was already sinking. Our strategists draw upon their knowledge and expertise not to let your ship crash on the iceberg of IT limitations.</p>
                               <br /> <p>Taking into account the inefficiencies and shortcomings identified in the gap analysis as well as your business development ambitions, we draw up a blueprint for your tech infrastructure to make sure all your goals will be met. We create strategies for different time frames — from 3(6) months to 5 years — to prioritize critical things in the short term, without losing sight of longer-run ambitions.</p>
                                </div>
                            </div>
                            <div className="item-main50">
                                <div className="item25">
                                    <div className="item-inner25">
                                        <img className="googlecloud" src="../assets/images/GoogleCloud.png" alt="" />
                                    </div>
                                </div>
                                <div className="item25">
                                    <div className="item-inner50">
                                        <img className="MicrosoftAssure" src="../assets/images/MicrosoftAssure.png" alt="" />
                                    </div>
                                </div>
                                <div className="item25">
                                    <div className="item-inner50">
                                        <img className="aws" src="../assets/images/aws.png" alt="" />
                                    </div>
                                </div>
                                <div className="item25">
                                    <div className="item-inner50">
                                        <img className="ovhcloud" src="../assets/images/OvhCloud.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default Tebt;