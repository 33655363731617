import React from "react";

const Technologies2 = () => {
    return (
        <>
            <div className="t-main">
                <div className="technology db">
                    <div className="home-main">
                        <div className="container">
                            <h1>Technology We Use !</h1>
                            <div className="inner-tecnology">
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t1.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t2.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t3.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t4.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t5.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t6.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img center">
                                            <img src="assets/images/t7.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t8.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img center">
                                            <img src="assets/images/t9.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t10.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t11.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t12.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t13.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img center">
                                            <img src="assets/images/t14.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img center">
                                            <img src="assets/images/t15.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t16.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t17.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t18.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t19.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img iend">
                                            <img src="assets/images/t20.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t21.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t22.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img ">
                                            <img src="assets/images/t23.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img className="down" src="assets/images/t24.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t25.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t26.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t27.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t28.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t29.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="img">
                                            <img src="assets/images/t30.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Technologies2;