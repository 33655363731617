import React from "react";
const JoinUs = () => {
    return (
        <>
            <div className="joninus-main db">
                <div className="container">
                    <div className="item-main50">
                        <div className="item50">
                            <h5>Join Us</h5>
                            <h2>Benefits Of Working <br />
                                With Us</h2>
                            <p>Creative, innovative and strategic. We have great achievements made together and looking to enhance</p>
                            <div className="item-main50">
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <div className="image-inner db">
                                                <img className="nohover" src="assets/images/j1.webp" alt="" />
                                            </div>
                                            <div className="contex">
                                                <p>          Cutting-edge solutions to keep your prand ahead.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <div className="image-inner db">
                                                <img className="nohover" src="assets/images/j2.webp" alt="" />
                                            </div>
                                            <div className="contex">
                                                <p>        Cutting-edge solutions to keep your brand ahead.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <div className="image-inner db">
                                                <img className="nohover" src="assets/images/j3.webp" alt="" />
                                            </div>
                                            <div className="contex">
                                                <p>        Cutting-edge solutions to keep your brand ahead.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <div className="image-inner db">
                                                <img className="nohover" src="assets/images/j4.webp" alt="" />
                                            </div>
                                            <div className="contex">
                                                <p>       Cutting-edge solutions to keep your brand ahead.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item50">
                            <div className="item-inner50">
                                <img className="join" src="assets/images/joinus.webp" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default JoinUs;