import { BaseInput } from "./input.style";
import React from "react";


const Input = ({...rest}) => {
    return (
        <>
            <BaseInput {...rest}
            
            />
        </>
    );
}

export default Input;