import React from "react";
import Header from "../Header";
import { PiChartLineThin } from "react-icons/pi";
import { FaCashRegister, FaHandsHoldingCircle, FaViruses } from "react-icons/fa6";
import { FcMultipleDevices } from "react-icons/fc";
import { SiRocket } from "react-icons/si";
import { MdHealthAndSafety, MdSecurity } from "react-icons/md";
import { GiHealthNormal } from "react-icons/gi";
import { RxBarChart } from "react-icons/rx";
const HealthCare = () => {
    return (
        <>
            <div className="health_main db">
                <div className="helth_inner backgroundimage">
                    <Header />
                    <div className="container">
                        <div className="main-heading">
                            <div className="heading-main helth_c"><h1>Healthcare Software Development Services</h1></div>
                            <div className="heading-main helth_c"><p>From start-ups and device manufacturers to healthcare payer and provider organizations, our expertise in healthcare software development can satisfy the unique requirements of this industry.</p><p>*instinctools is passionate about and committed to delivering first-class solutions. The digital products we help clients build achieve better patient outcomes, simplify medical workflows, and help healthcare organizations provide high-quality care — all with a cost-saving and highly secure approach.</p></div>
                        </div>
                    </div>
                </div>
                <div className="second db p50">
                    <div className="container">
                        <div className="item-main50 db">
                            <h2 className="tc db">Addressing the Needs of Healthcare Companies with Top-Notch Software Development</h2>
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="line line10">
                                        <h5>  <PiChartLineThin />High speed to market</h5></div>
                                    <p>Our tried and tested hiring method and always-available rockstar developers help you snatch leadership in the market by being first at all points.</p>

                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="line line10">
                                        <h5>  <FaHandsHoldingCircle />High speed to market</h5></div>
                                    <p>Our tried and tested hiring method and always-available rockstar developers help you snatch leadership in the market by being first at all points.</p>

                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="line line10">
                                        <h5>  <FcMultipleDevices />High speed to market</h5> </div>
                                    <p>Our tried and tested hiring method and always-available rockstar developers help you snatch leadership in the market by being first at all points.</p>

                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="line line10">
                                        <h5>  <SiRocket />High speed to market</h5></div>
                                    <p>Our tried and tested hiring method and always-available rockstar developers help you snatch leadership in the market by being first at all points.</p>

                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="line line10">
                                        <h5>  <MdSecurity />High speed to market</h5> </div>
                                    <p>Our tried and tested hiring method and always-available rockstar developers help you snatch leadership in the market by being first at all points.</p>

                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="line line10">
                                        <h5>  <FaViruses />High speed to market</h5></div>
                                    <p>Our tried and tested hiring method and always-available rockstar developers help you snatch leadership in the market by being first at all points.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="third db ">
                    <div className="container">
                        <div className="item-main50">
                            <div className="item50">
                                <div className="item-inner50">
                                    <h3>Making Healthcare Workflows Effective</h3>
                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <p>Whether it’s a fresh idea or a major system in need of a redesign, our full-stack development team is devoted to providing high-quality guidance and services at every stage of the software development process for your healthcare start-up or organization.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="forth db">
                    <div className="container">
                        <div className="inner-forth db p50" >
                            <div className="item3">
                                <div className="left"><GiHealthNormal /></div>
                                <div className="center">
                                    <h5>
                                        Medical Practice Management Software
                                    </h5>
                                </div>
                                <div className="right"><p>Leverage the latest technologies to automate and simplify your everyday operations. From revenue cycle management and health insurance claims software to EHR management and patient portals, our development team will devote its full efforts to helping you achieve your maximum efficiency.</p>

                                </div>
                            </div>
                            <div className="item3">
                                <div className="left"><RxBarChart /></div>
                                <div className="center">
                                    <h5>
                                        Healthcare CRMs
                                    </h5>
                                </div>
                                <div className="right"><p>Improve your healthcare organization’s customer relationships by providing easier access, convenient communication features, and personalized care — all guarded by reliable and robust security.</p><p>Understanding your audience and targeting it precisely is vital in the healthcare industry. We provide the necessary tools to achieve true personalization, including consumer engagement solutions, customer satisfaction surveys, as well as online and cloud-based CRM solutions.</p>
                                </div>
                            </div>
                            <div className="item3">
                                <div className="left"><FaCashRegister /></div>
                                <div className="center">
                                    <h5>
                                        Hospital Management Systems
                                    </h5>
                                </div>
                                <div className="right"><p>Advances in MedTech and healthcare software development have made streamlining hospital workflows much easier. We specialize in hospital applications development of all types, such as:</p>
                                    <ul>
                                        <li className="kadimadi white" >Outpatient/inpatient record management systems</li>
                                        <li className="kadimadi white" >
                                            Hospital asset tracking
                                        </li>
                                        <li className="kadimadi white" >Inventory management for hospitals</li>
                                        <li className="kadimadi white" >EMR/EHR</li>
                                        <li className="kadimadi white" >Revenue cycle management</li>
                                        <li className="kadimadi white" >Clinical communication and collaboration</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="item3">
                                <div className="left"><MdHealthAndSafety /></div>
                                <div className="center">
                                    <h5>
                                        Health Insurance Software
                                    </h5>
                                </div>
                                <div className="right"><b><span>Traditional Health Plans </span></b><p>Cut costs, save time, minimize human error, and optimize your processes with such solutions and features as:</p>
                                    <ul>
                                        <li className="kadimadi white" >Insurance claims management</li>
                                        <li className="kadimadi white" >Healthcare insurance data and records handling</li>
                                        <li className="kadimadi white" >Benefits administration</li>

                                    </ul>
                                    <p>Build solutions for individual and family health insurance plans, Medicare, Medicaid, and employer groups while integrating them with EHR software.</p>
                                    <b><span>Consumer Directed Healthcare (CDHC) </span></b>
                                    <p> health insurance software can help you effectively overcome the complexities of consumer-directed health plans, such as:</p>
                                    <ul>
                                        <li className="kadimadi white" >Flexible Spending Accounts (FSA)</li>
                                        <li className="kadimadi white" >Health Savings Accounts (HSA)</li>
                                        <li className="kadimadi white" >Voluntary Employees’ Beneficiary Association (VEBA)</li>
                                        <li className="kadimadi white" >Health Reimbursement Arrangements (HRA)</li>
                                        <li className="kadimadi white" >Consolidated Omnibus Budget Reconciliation Act (COBRA)</li>
                                        <li className="kadimadi white" >Defined contribution and wellness plans.</li>
                                    </ul>
                                    <p>Our company responds to the demands of a complex and dynamic consumer-directed healthcare market and helps clients manage eligibility, enrollment, payments, and substantiation through self-service applications and portals while ensuring compliance with IRS regulations.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HealthCare;