import React from "react";
import Header from "../Header";
import { BsGraphUp } from "react-icons/bs";
import { FaPencilRuler } from "react-icons/fa";
import { BsBoxArrowInUpRight } from "react-icons/bs";
import { SlSettings } from "react-icons/sl";
import { FaMedal } from "react-icons/fa";
import { FaMobileScreenButton } from "react-icons/fa6";
import { TbSquareRoundedCheck } from "react-icons/tb";
const Csd = () => {
    return (
        <>
            <div className="csd-main db">
                <div className="csd-inner backgroundimage">
                    <Header />
                    <div className="container">
                        <div className="main-heading">
                            <div className="heading-main csd-c csd"><h1> Software Development Services</h1></div>
                            <div className="heading-main csd-c csd"><p>Get what you need and when you need it with our  software development company. Our team of 350+ software developers builds with your business and employees in mind, not just the general software user.</p></div>

                        </div>
                    </div>
                </div>
                <div className="second db p50">
                    <div className="container">
                        <div className="inner-second db tc">
                            <h5>
                                Whatever tech challenge you have, our development services can solve it
                            </h5>
                            <p>

                                As your software development agency, we build bespoke solutions that span web, mobile, desktop, server-side, IoT, and everything in between. From ideation to integration, *instinctools works hand-in-glove with you every step of your engineering journey.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="third db">
                    <div className="container">
                        <div className="item-main50">
                            <div className="item50">
                                <div className="item-inner50">
                                   
                                    <div className="line line10 "> 
                                        <h5><BsGraphUp className="red" /> Product development</h5>
                                    </div>
                                    <p>Our BAs gear up your project for successful implementation by eliciting requirements, prioritizing the backlog, and getting your project documentation lined up. We also deliver visual representations of your digital solutions so you can build the right product on the first try.</p>
                                    <ul>
                                        <li className="kadimadi white" >Scope definition and documentation</li>
                                        <li className="kadimadi white" >Full-cycle project development support</li>
                                        <li className="kadimadi white" >Requirements gathering and validation</li>
                                        <li className="kadimadi white" >User acceptance testing</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="line line10">
                                        <h5><FaPencilRuler className="green"/> UX and design services</h5>
                                    </div>
                                    <p>Great application design goes beyond aesthetics — its user-centric interfaces that turn first-time users into loyal customers. Our application development company finds a fair balance between creativity and usability to keep your solutions pretty, yet functional.</p>
                                    <ul>
                                        <li className="kadimadi white" >Prototyping and wireframing</li>
                                        <li className="kadimadi white" >Usability testing</li>
                                        <li className="kadimadi white" >Frontend development</li>
                                        <li className="kadimadi white" >Redesign services</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="line line10">
                                        <h5><BsBoxArrowInUpRight className="gray"/> Software integration services</h5>
                                    </div>
                                    <p>Put an end to your data chaos by enabling smooth data exchange between your business software systems. No matter your integration challenges or deployment approach, our  software company introduces simple, yet scalable integrations in line with HIPAA, GAMP, PCI DSS, and other regulations.</p>
                                    <ul>
                                        <li className="kadimadi white" >P2P /ESB /Hub-and-spoke integration</li>
                                        <li className="kadimadi white" >API development</li>
                                        <li className="kadimadi white" >Cloud integration</li>
                                        <li className="kadimadi white" >IoT integration</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="line line10">
                                        <h5><SlSettings  className="yellow"/> Enterprise automation</h5>
                                    </div>
                                    <p>Since 2000, our software application development company has been assisting global enterprises in creating software that automates complex business processes. Our solutions shore up your digital foundation and enable your company to move at the speed of business change. </p>
                                    <ul>
                                        <li className="kadimadi white" >Enterprise software modernization and integration</li>
                                        <li className="kadimadi white" >Digital transformation</li>
                                        <li className="kadimadi white" >Intelligent enterprise automation</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="line line10">
                                        <h5><FaMedal className="yellow"/> Quality assurance</h5>
                                    </div>
                                    <p>We deliver full-cycle software application testing services to validate every aspect of your software solution, from functional to non-functional components. Our QA engineers bank on a combination of manual and automated testing methods to increase the release pace and reduce the cost of testing by up to 30%.</p>
                                    <ul>
                                        <li className="kadimadi white" >Manual and automation testing</li>
                                        <li className="kadimadi white" >API testing</li>
                                        <li className="kadimadi white" >Security testing</li>
                                        <li className="kadimadi white" >Web and mobile quality assurance</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="line line10">
                                        <h5><FaMobileScreenButton className="white"/> Mobile development</h5>
                                    </div>
                                    <p>Secure, user-focused, and impactful, our mobile apps empower you to break into the mobile market and grow your business. Our dedicated team of mobile developers builds native and cross-platform mobile applications with big-time features, keeping user and stakeholder feedback at the core of all processes.</p>
                                    <ul>
                                        <li className="kadimadi white" >Android mobile app development</li>
                                        <li className="kadimadi white" >iOS mobile app development</li>
                                        <li className="kadimadi white" >Cross-platform mobile applications</li>
                                        <li className="kadimadi white" >Hybrid mobile apps</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="line line10">
                                        <h5><TbSquareRoundedCheck className="red" /> Software modernization services</h5>
                                    </div>
                                    <p>Rely on our  development services to upgrade and future-proof your outdated business systems. Our  software development team delivers a revamped version of your software that is easy to maintain, intuitive to work with, and fast to accommodate your growing business needs.</p>
                                    <ul>
                                        <li className="kadimadi white" >Cloud migration and containerization</li>
                                        <li className="kadimadi white" >Application customization</li>
                                        <li className="kadimadi white" >Complete re-engineering</li>
                                        <li className="kadimadi white" >Dev(Sec)Ops transformation</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="forth db ">
                    <div className="container">
                        <div className="inner-forth">
                            <div className="left db">
                                <img src="assets/images/software-development-img3.jpg" alt="" className="db bb" /></div>
                            <div className="right db">
                                <h2>
                                    Turn your projects <span>into success stories with our time-tested </span> Delivery Framework</h2>
                                <p>At *instinctools, we know that however great your product idea is, nothing much will come of it without proper implementation. Built on the principles of Disciplined Agile Delivery and PMBoK 7 and honed by 650+ projects, our proprietary Delivery Framework brings consistency, clarity, and structure to the software development process. Sticking to this methodology, we ensure you get first-rate quality software for business, delivered on time and on budget.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fifth db p50">
                    <div className="container">
                        <div className="item-main50">
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="line">
                                        <h5> Architecture-driven approach</h5>
                                    </div>
                                    <p>We put architecture-driven methodology at the center of your software project to set the context for all development processes. Our developers use well-defined development artifacts and optimal solution architectures as a launchpad for reliable, risk-free, and business-driven solutions.</p>
                                    <ul>
                                        <li className="kadimadi white" >Requirements analysis</li>
                                        <li className="kadimadi white" >Solution architecture design</li>
                                        <li className="kadimadi white" >Detailed documentation</li>
                                        <li className="kadimadi white" >Architecture review</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="line">
                                        <h5> Tried-and-true engineering practices</h5>
                                    </div>
                                    <p>It’s not enough to add Agile software development and the latest software development tools to your product engineering checklist. You need to establish a culture of continuous learning, collaboration, and adaptation within your development team — and our company checks all these boxes.</p>
                                    <ul>
                                        <li className="kadimadi white" >Code Quality Gates</li>
                                        <li className="kadimadi white" >Unit testing</li>
                                        <li className="kadimadi white" >Code review</li>
                                        <li className="kadimadi white" >Knowledge sharing mechanism</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="line">
                                        <h5> PM Handbook</h5>
                                    </div>
                                    <p>Guided by the best project management practices, advanced tools, and comprehensive checklists, our team guarantees your project hits the mark.</p>
                                    <ul>
                                        <li className="kadimadi white" >Transparent process</li>
                                        <li className="kadimadi white" >Efficient communication</li>
                                        <li className="kadimadi white" >Risk management</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="line">
                                        <h5> Ease of governance and escalation</h5>
                                    </div>
                                    <p>Our governance model enables fluid collaboration between the client’s and *instinctools’ teams throughout the entire project. With regular reviews at every level — from team leaders to executives — we align closely with your vision, managing risks and keeping your project on track.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Csd;