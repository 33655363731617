import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import emailjs from '@emailjs/browser';
import { Link } from "react-router-dom";
import { object, string } from "yup";
import { useFormik } from "formik";
import Input from "./UI/Input/Input";

const HireUs = () => {
    const [phoneValue, setPhoneValue] = useState('');
    const form = useRef();
    const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false);
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

    const contactSchema = object({
        user_name: string().required("*Please Enter First Name"),
        user_last_name: string().required("*Please Enter Last Name"),
        user_email: string()
            .required("*Please Enter Email")
            .email("*Please Enter Valid Email"),
        user_project: string().max(400, "*Maximum 400 characters allowed"),
        user_about: string().max(400, "*Maximum 400 characters allowed"),
    });

    const formik = useFormik({
        initialValues: {
            user_name: "",
            user_last_name: "",
            user_email: "",
            user_project: "",
            user_about: ""
        },
        validationSchema: contactSchema,
        onSubmit: (values) => {
            if (!isSubmitEnabled) {
                alert('Please accept the Terms of Service and Privacy Policy to proceed.');
                return;
            }

            emailjs
                .sendForm('service_i83xhdg', 'template_dy0pdwx', form.current, {
                    publicKey: 'OB3uf5NUZFNBOSy8I',
                })
                .then(
                    () => {
                        console.log('SUCCESS!');
                        form.current.reset();
                        setPhoneValue('');
                        formik.resetForm();
                        setIsSubmitEnabled(false);
                    },
                    (error) => {
                        console.log('FAILED...', error.text);
                    }
                );
        }
    });

    const { handleChange, handleSubmit, handleBlur, values, errors, touched } = formik;

    useEffect(() => {
        // Check if all required fields are filled
        const allFilled = Object.values(values).every(field => field !== '');
        setIsCheckboxEnabled(allFilled);
    }, [values]);

    const handleCheckboxChange = (e) => {
        setIsSubmitEnabled(e.target.checked);
    };

    return (
        <>
            <div className="home-main">
                <div className="hire">
                    <Header />
                    <div className="container">
                        <div className="inner-hire">
                            <div className="title">
                                <h3>Free <span>project quote</span></h3>
                                <p>Fill out the enquiry form and we'll get back to you as soon as possible.</p>
                                <div className="data">
                                    <div className="wrapper-main">
                                        <div className="wrapper hireus-wrapper">
                                            <form ref={form} className="form-data" id="myForm" encType="multipart/form-data" onSubmit={handleSubmit}>
                                                <h2>Hire Us</h2>
                                                <table>
                                                    <div className="Registration-box">
                                                        <tr>
                                                            <td className="label">
                                                                <label htmlFor="user_name">First Name</label>
                                                            </td>
                                                            <td>
                                                                <Input
                                                                    type="text"
                                                                    name="user_name"
                                                                    className="hello"
                                                                    placeholder="Enter Your First Name"
                                                                    onChange={handleChange}
                                                                    value={values.user_name}
                                                                    onBlur={handleBlur}
                                                                />
                                                                <span className="error"  style={{ color: "red", marginTop: "-10px" }}>
                                                                    {touched.user_name && errors.user_name ? errors.user_name : ''}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="label">
                                                                <label htmlFor="user_last_name">Last Name</label>
                                                            </td>
                                                            <td>
                                                                <Input
                                                                    type="text"
                                                                    name="user_last_name"
                                                                    className="hello"
                                                                    placeholder="Enter Your Last Name"
                                                                    onChange={handleChange}
                                                                    value={values.user_last_name}
                                                                    onBlur={handleBlur}
                                                                />
                                                                <span className="error"  style={{ color: "red", marginTop: "-10px" }}>
                                                                    {touched.user_last_name && errors.user_last_name ? errors.user_last_name : ''}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="label">
                                                                <label htmlFor="user_email">Email :-</label>
                                                            </td>
                                                            <td>
                                                                <Input
                                                                    type="text"
                                                                    name="user_email"
                                                                    className="hello"
                                                                    placeholder="Email"
                                                                    onChange={handleChange}
                                                                    value={values.user_email}
                                                                    onBlur={handleBlur}
                                                                />
                                                                <span className="error"  style={{ color: "red", marginTop: "-10px" }}>
                                                                    {touched.user_email && errors.user_email ? errors.user_email : ''}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="label">
                                                                <label htmlFor="phone">Phone Number :-</label>
                                                            </td>
                                                            <td>
                                                                <PhoneInput
                                                                    placeholder="Enter phone number"
                                                                    defaultCountry="IN"
                                                                    value={phoneValue}
                                                                    onChange={setPhoneValue}
                                                                    maxLength="13"
                                                                    minLength="10"
                                                                    name="user_phonenumber"
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td>
                                                                <textarea
                                                                    name="user_project"
                                                                    value={values.user_project}
                                                                    onChange={handleChange}
                                                                    placeholder="Tell Us About Your Project"
                                                                    maxLength="400"
                                                                    rows="6"
                                                                    cols="38"
                                                                ></textarea>
                                                                <span className="error"  style={{ color: "red", marginTop: "-10px" }}>
                                                                    {touched.user_project && errors.user_project ? errors.user_project : ''}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td>
                                                                <textarea
                                                                    name="user_about"
                                                                    value={values.user_about}
                                                                    onChange={handleChange}
                                                                    placeholder="How did you hear about us?"
                                                                    maxLength="400"
                                                                    rows="6"
                                                                    cols="38"
                                                                ></textarea>
                                                                <span className="error"  style={{ color: "red", marginTop: "-10px" }}>
                                                                    {touched.user_about && errors.user_about ? errors.user_about : ''}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="bhaicheck">
                                                            <td><input type="checkbox"
                                                                className="BhaiCheck" /></td>
                                                            <td className="text" >I need to sign the NDA first</td>
                                                        </tr>
                                                        <tr className="bhaicheck">
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    className="BhaiCheck"
                                                                    checked={isSubmitEnabled}
                                                                    onChange={handleCheckboxChange}
                                                                    disabled={!isCheckboxEnabled}
                                                                />
                                                            </td>
                                                            <td className="text"  >I have read and accept the <Link to="/Terms and Conditions">Terms of Service</Link> & <Link to="/PrivacyPolicy">Privacy Policy</Link></td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <button type="submit" className="submitb" id="submit"  disabled={!isSubmitEnabled}>Submit</button>
                                                            </td>
                                                        </tr>
                                                    </div>
                                                </table>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HireUs;
