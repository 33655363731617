
import styled from "styled-components";

export const BaseInput = styled.input`

    display: block;
    font-size: 0.2rem;
    font-weight: 400;
    line-height: 1.5;
    color: #747d88;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -moz-appearance: none;
    appearance: none;
    border-radius: 10px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    
`;