import React from "react";
import Header from "./Header";
import ScrollToTop from "react-scroll-to-top";

const HMain = () => {
    return (
        <>
                <div className="video backgroundimage" >
                    <Header />
                    <ScrollToTop smooth color="#6f00ff" />
                    <div className="hmain tc">
                        <div className="container">
                            <div className="inner-hmain">
                                <h1 className="spire-animation">
                                    S<span className="hmainr">P</span>RIER </h1>
                                <h4 class="tech-animation">TECHN<span className="rotateO">O</span>L<span className="rotateO">O</span>GY C<span className="rotateO">O</span>NSULTANCY</h4>

                                <span className="lastc">Agile IT Solution for a Fast Paced World.</span><br />
                            </div>
                        </div>
                    </div>
            </div>
        </>
    );
}
export default HMain;