

import React from "react";
import Header from "../Header";
import { GrApple } from "react-icons/gr";
import { AiFillAndroid } from "react-icons/ai";
import { FcMultipleDevices } from "react-icons/fc";
const Application_Development_Designing = () => {
    return (
        <>
            <div className="add-main db">
                <div className="inner-add backgroundimage">
                    <Header />
                    <div className="container">
                        <div className="main-heading">
                            <div className="heading-main add_c "><h1>Outsource Mobile App Development</h1></div>
                            <div className="heading-main add_c "><p>Move beyond platforms and devices to business outcomes.</p></div>
                        </div>
                    </div>
                </div>
                <div className="second db p50">
                    <div className="container">
                        <div className="item-main50">
                            <div className="item50">
                                <div className="item-inner50">
                                    <h3>Mobile solutions
                                        we build</h3>
                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <p>We help our clients take advantage of rapid tech changes without encumbering their businesses. We create, integrate, and leverage features in their mobile applications and enterprise architectures that ensure tangible results.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="third db">
                    <div className="container">
                        <div className="inner-third db">
                            <div className="item3">
                                <div className="left"><GrApple /></div>
                                <div className="center">
                                    <h5>
                                        Native iOS applications
                                    </h5>
                                </div>
                                <div className="right"><p>Native apps are a crucial differentiator if maximum productivity and flawless user experience are required. Our expertise and best practices enable us to deliver a wide range of  mobile apps for Apple products, including wearables, smart TVs, and more. Our mobile app developers strictly follow Apple’s Human Interface Guidelines, and other best practices, that solve various challenges such as battery consumption and network speed.</p></div>
                            </div>
                            <div className="item3">
                                <div className="left green"><AiFillAndroid /></div>
                                <div className="center">
                                    <h5>
                                        Native Android applications
                                    </h5>
                                </div>
                                <div className="right"><p>Commercial-grade mobile apps amplify a company’s multi-platform, multi-device strategy. Since the Android platform was released, we’ve developed various Android apps, ranging from lifestyle and leisure mobile apps to complex enterprise applications. After developing Android apps for over ten years, we have the skills to quickly release a product on the market, ensure that it incorporates enterprise-grade security, and has an excellent, user-friendly interface.</p></div>
                            </div>
                            <div className="item3">
                                <div className="left"><img className="bn" src="../assets/images/cross.png" alt="" /></div>
                                <div className="center">
                                    <h5>
                                        Cross-platform development
                                    </h5>
                                </div>
                                <div className="right"><p>Reaching out to a larger audience while reducing development and maintenance costs, and speeding up time to market? With cross-platform development, it turns out to be as good as it sounds. Utilizing best-of-breed frameworks, *instinctools’ experts never compromise on quality, and craft solutions to achieve a native app-like look and feel both for Android and iOS adherents.</p></div>
                            </div>
                            <div className="item3">
                                <div className="left"><FcMultipleDevices /></div>
                                <div className="center">
                                    <h5>
                                        Hybrid mobile applications
                                    </h5>
                                </div>
                                <div className="right"><p>Hybrid mobile apps are the perfect solution to target a broad audience using the product on mobile and web platforms. Due to its web-based environment, the maintenance is much easier and quicker, and using native APIs allows for the implementation of device functionality, such as GPS. We build fast, scalable hybrid mobile applications that help businesses save time and reduce costs on mobile app development, testing, and support processes.</p></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="forth db">
                    <div className="container">
                        <div className="inner-forth db">
                            <div className="heading db tc">
                                <h4>Mobile software development for your business needs</h4><p>*instinctools builds a wide range of  mobile solutions for B2B, B2C, and B2E systems. We help our customers meet their business goals, delivering high-scale customized mobile solutions.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fifth db p50">
                    <div className="container">
                        <div className="item-main50">
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="line"><h5>Enterprise mobile app development</h5></div>
                                    <p>Building robust applications that facilitate business processes and enable efficient remote work have become an integral part of our mobile application development. As work happens wherever your team is, it’s crucial for people to securely access the digital work environment from anywhere, at any time, on any mobile device.</p>
                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                  <div className="line">  <h5>Our enterprise mobile app development services include:</h5></div>
                                    <ul>
                                        <li className="kadimadi white" >workforce apps for field staff</li>
                                        <li className="kadimadi white" >logistics and transportation mobile solutions</li>
                                        <li className="kadimadi white" >2FA secure mobile apps</li>
                                        <li className="kadimadi white" >production management</li>
                                        <li className="kadimadi white" >centralized mobile dashboards for C-suite members</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <div className="line"><h4>Consumer mobile app development</h4></div><p>We put your users’ needs first by delivering lightning-fast, feature-packed apps that improve customer satisfaction, strengthen your brand, and boost conversions. Leveraging our vast expertise in mobile application development, we design impeccable apps that meet industry-specific challenges.</p>
                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <ul>
                                        <li className="kadimadi white" >Medical and Healthcare Apps</li>
                                        <li className="kadimadi white" >Multimedia Applications</li>
                                        <li className="kadimadi white" >IoT and Wearable Solutions</li>
                                        <li className="kadimadi white" >Travel and Booking Apps </li>
                                        <li className="kadimadi white" >Sport and Fitness Apps</li>
                                        <li className="kadimadi white" >Retail and E-commerce Apps  </li>
                                        <li className="kadimadi white" >E-Learning Apps</li>
                                        <li className="kadimadi white" >Location-Based Apps</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Application_Development_Designing;