import React from "react";
const AboutUs = () => {
    return (
        <>
           <div className="about-main">
                <div className="container">
                    <div className="inner-about">
                        <div className="heading">
                            <h1>About Us</h1>
                            <div className="paragraph">


                                <p>An organization founded on competence and trust in IT work practices. In 2024, Sprier Technology Consultancy, a Surat, Gujarat-based provider of IT solutions, sets out on its adventure. The company's founding principle was to offer a smooth flow of services to all kinds of industries. By giving you a one stop shop for all your software and technology-related needs, our ultimate goal is to support economic progress. Our Dedicated Team Members at Sprier Technology Consultancy use cutting-edge technologies to assist you maximize your company's potential. In all we do, we keep clients and codes in mind. Our unwavering support is only a few steps away. Please get in contact with us right now; we look forward to transforming your ideas into profitable venture.</p></div>
                        </div>
                        <div className="image">
                            <div className="image-inner">
                                <div className="item">
                                    <div className="item-inner">
                                        <img src="assets/images/1.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <img src="assets/images/2.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <h2>About <br />
                                            Sprier Technology <br />
                                            Consultancy</h2>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="text">
                                            <div className="text-inner">
                                                <h3>Our Vision </h3>
                                                <p>"To be the pioneering force in transforming industries through innovative technology solutions. We envision a future where our cutting-edge IT services empower businesses worldwide to achieve unprecedented efficiency, growth, and sustainability."
                                                </p>
                                            </div>
                                        </div>
                                        <div className="text">
                                            <div className="text-inner">
                                                <h3>Our Mission </h3>
                                                <p>"To empower businesses and individuals through innovative technology solutions, fostering growth and efficiency. We strive to deliver exceptional quality in software development, cybersecurity, and IT consulting, ensuring our clients achieve their strategic objectives with confidence and reliability. By staying at the forefront of technological advancements and maintaining a commitment to excellence, we aim to be the trusted partner of choice for organizations seeking transformative digital solutions worldwide."
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <Footer/> */}
        </>
    );
}
export default AboutUs;