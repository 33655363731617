

import React from "react";
import Header from "../Header";
import { BsVirus } from "react-icons/bs";
import { FaLaptopCode, FaMagic } from "react-icons/fa";
import { PiChatsThin } from "react-icons/pi";
import { GiWoodFrame } from "react-icons/gi";
import { TbFrame } from "react-icons/tb";
import { RiFileSearchFill } from "react-icons/ri";
import { LiaLaptopCodeSolid } from "react-icons/lia";
import { FaMobileScreen } from "react-icons/fa6";
import { MdComputer } from "react-icons/md";
import { FcMultipleSmartphones } from "react-icons/fc";
const UiUx = () => {
    return (
        <>
            <div className="ui-ux db">
                <div className="inner-uiux backgroundimage">
                    <Header />
                    <div className="container">
                        <div className="main-heading">
                            <div className="heading-main ui_c uiux"><h1>UI/UX <br /> Design Company</h1></div>
                            <div className="heading-main ui_cb uiux"><p><b>Good design</b> can go unnoticed. <b>Bad design</b> will surely not.<br />Our UI/UX design services company makes sure <b>yours is good</b>.</p>
                                <ul>
                                    <li className="kadimadi" >Step up performance by designing experiences users love</li>
                                    <li className="kadimadi" >Emphasize your product’s functionality</li>
                                    <li className="kadimadi" >Improve user experience and internal efficiency</li>
                                    <li className="kadimadi" >Double down on customer retention and profitability</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="second db">
                    <div className="container">
                        <div className="inner-second db">
                            <div className="heading tc">
                                <h3>Balancing functionality and usability in enterprise and consumer solutions</h3><p>
                                    Tap into our UI and UX design services to create pragmatic and ergonomic product designs that don’t compromise on aesthetics.
                                </p>
                            </div>
                            <div className="item-main50">
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="line line10">
                                            <h5>  <BsVirus /><span>UX and design for </span><br />Enterprise Software</h5>
                                        </div>
                                        <p>Magnify the impact and value of your business systems and make them an easy-to-use companion for your teams. Our company produces user-friendly designs for your enterprise systems that mimic your workflows and improve internal interactions.</p>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="line line10"><h5>  <FaLaptopCode /><span>UX and design for </span><br />Consumer Applications</h5></div>

                                        <p>Turn to our UI design services company and create a consistent visual language for your customer-focused solutions. Our impactful and compelling visuals help you communicate the value of your business, cut through the noise, and reel in new users.</p>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <h2><span>UI design services — </span><br /> the silent ambassador of your brand</h2>
                                        <p>Our user experience design services are aimed at showcasing the true picture of your business through impactful, branded digital interfaces tailor-made to the user. Instinctools delivers user interfaces that create a lasting impression and pull their weight to connect with the audience.</p>
                                        <p>We help you reach your revenue and business goals as <b>a one-off partner</b> or <b>long-term advisor</b> that plugs into your team and helps your digital solutions evolve.</p>

                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="image">
                                            <img src="../assets/images/ui-ux-design-services-image2.avif" alt="" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="second">
                    <div className="container">
                        <div className="inner-second">
                            <div className="heading">
                                <h3>
                                    From ideation to polished interfaces: our end-to-end UI/UX design services
                                </h3><p>
                                    Our UI UX design & development services help you meet and exceed every need of your users —  from layout to color scheme — and convey your message in the most efficient way.
                                </p>
                            </div>
                            <div className="item-main50">
                                <div className="item50">
                                    <div className="item-inner50">

                                        <div className="line line10"><h5><PiChatsThin /> <span></span>Design innovation consulting</h5>
                                        </div>                             <p>Great designs should be experienced, not seen. Our UX design services help you spot opportunities in unmet user needs, gain solid knowledge of market trends, and transform low-converting designs.</p>
                                        <ul>
                                            <li className="kadimadi" >User and market research</li>
                                            <li className="kadimadi" >UI/UX audit</li>
                                            <li className="kadimadi" >Discovery workshops</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">

                                        <div className="line line10"><h5><GiWoodFrame /> <span></span>Wireframing</h5>
                                        </div>             <p>We rely on conceptual wireframing to create a solid visual foundation for your product. Each wireframe is tested by our experts and validated by your team to make sure the project follows the right path.</p>
                                        <ul>
                                            <li className="kadimadi" >Customer journey mapping</li>
                                            <li className="kadimadi" >User flow development</li>
                                            <li className="kadimadi" >Interactive wireframing</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">

                                        <div className="line line10"> <h5><TbFrame /> <span></span>Prototyping</h5>
                                        </div>             <p>Following research data and requirements, our designers craft clickable prototypes that demonstrate your design concept to stakeholders. Our prototypes allow for quick iterations before the final product, saving you time and effort in the development.</p>
                                        <ul>
                                            <li className="kadimadi" >Feasibility check</li>
                                            <li className="kadimadi" >Wireframe iteration</li>
                                            <li className="kadimadi" >Low-/High-fidelity prototyping</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">

                                        <div className="line line10"><h5><RiFileSearchFill /> <span></span>Usability testing</h5>
                                        </div>                   <p>Our developers take the guesswork out of your design decisions and make sure your UX/UI clicks with the end user. We run a 360-degree analysis of an application’s interface and logic to iron out the kinks and eliminate flaws.</p>
                                        <ul>
                                            <li className="kadimadi" >Expert-based / user testing</li>
                                            <li className="kadimadi" >UX/UI audit</li>
                                            <li className="kadimadi" >Accessibility assessment</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">

                                        <div className="line line10"> <h5><LiaLaptopCodeSolid /> <span></span>Frontend development </h5>
                                        </div>                   <p>Resort to our UI UX design and development services and connect the dots between business goals and user interactions. Our interfaces are designed with close attention to detail, allowing users to find what they need with the least effort..</p>
                                        <ul>
                                            <li className="kadimadi" >Web front-end development</li>
                                            <li className="kadimadi" >Front-end development for mobile</li>
                                            <li className="kadimadi" >Cross-platform front-end development</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">

                                        <div className="line line10"><h5><FaMagic /> <span></span>Redesign and modernization services</h5></div>
                                        <p>Breathe new dynamics into the look and feel of your solutions and overcome the challenges of outdated user flows. Our UI services are rooted in the latest trends and technologies that ensure elevated and intuitive experiences augmented with cutting-edge features.</p>
                                        <ul>
                                            <li className="kadimadi" >Tech modernization consulting</li>
                                            <li className="kadimadi" >Application redesign</li>
                                            <li className="kadimadi" >Legacy system modernization</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="third db" >
                    <div className="container">
                        <div className="inner-third p50">
                            <div className="heading">
                                <h3>User-centered design across platforms</h3>
                                <p>Expand your business reach and support workplace flexibility with our platform-agnostic designs that look great on every device.</p>
                            </div>
                            <div className="item-main50">
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="line line10">
                                            <h5><FaMobileScreen /><span>UI design services — </span><br />Mobile design</h5>
                                        </div>                                        <p>We provide end-to-end mobile UI UX design services that turn your high-level vision into a dynamic and feature-filled interface. Our sleek designs ensure smooth product adoption, captivate your audience, and skyrocket conversion rates. </p>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="line line10">
                                            <h5><MdComputer /><span>UI design services — </span><br />Web design</h5>
                                        </div>                                        <p>Rely on our web UI design services to create responsive and SEO-friendly web designs that reduce the friction between you and the customer. We make sure our designs serve their purpose and help your business growth.</p>
                                    </div>
                                </div>
                                <div className="item50">
                                    <div className="item-inner50">
                                        <div className="line line10">
                                            <h5><FcMultipleSmartphones /><span>UI design services — </span><br />Cross-platform design</h5>
                                        </div>
                                        <p>Deliver holistic, near-native experiences based on cross-platform cohesion and reach your customers wherever they are. We check your designs on real devices to make sure they work as intended. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UiUx;