import React from "react";
import Header from "./Header";
const PrivacyPolicy = () => {
    return(
        <>  
            <div className="PrivacyPolicy">
        <Header/>
                <div className="container">
                    <div className="inner-PrivacyPolicy">
                        <div className="heading">
                            <h1>Privacy Policy</h1>
                        </div>
                        <p>Creating a privacy statement for an IT company involves outlining how the company collects, uses, and protects personal data. This statement should comply with relevant privacy laws and regulations, such as the General Data Protection Regulation (GDPR) for European clients, and India's Personal Data Protection Bill, if applicable.</p><br />
                        <p>Here is a comprehensive privacy statement template tailored for an IT company:</p>
                        <h3>Privacy Statement</h3>
                        <h4>1. Introduction</h4>
                        <p>Sprier Technology Consultancy ("we", "our", "us") is committed to protecting the privacy of our clients and users. This Privacy Statement outlines our practices regarding the collection, use, and protection of personal information.</p>
                        <h4>2. Information We Collect</h4>
                        <p>We may collect and process the following types of personal information:</p>
                        <ul>
                            <li>Personal Identification Information: Name, email address, phone number, mailing address, and other contact details.</li><br />
                            <li>Technical Information: IP address, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access our services.</li><br />
                            <li>Usage Data: Information about how you use our website, products, and services.</li><br />
                            <li>Marketing and Communications Data: Your preferences in receiving marketing from us and your communication preferences.</li><br />
                        </ul>
                        <h4>3. How We Collect Information</h4>
                        <p>We collect information in the following ways:</p>
                        <ul>
                            <li>Direct Interactions: You provide us with your personal data when you fill out forms or correspond with us by post, phone, email, or otherwise.</li><br />
                            <li>Automated Technologies: As you interact with our website, we may automatically collect technical data about your equipment, browsing actions, and patterns.</li><br />
                            <li>Third Parties or Publicly Available Sources: We may receive personal data about you from various third parties and public sources.</li><br />
                        </ul>
                        <h4>4. Use of Information</h4>
                        <p>We use the collected information for the following purposes:</p>
                        <ul>
                            <li>To provide, operate, and maintain our services.</li><br />
                            <li>To improve, personalize, and expand our services.</li><br />
                            <li>To understand and analyze how you use our services.</li><br />
                            <li>To develop new products, services, features.</li><br />
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PrivacyPolicy;