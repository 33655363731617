import React from "react";
import Header from "../Header";
import { IoSearch } from "react-icons/io5";
import { MdContentPaste } from "react-icons/md";
import { FaDatabase } from "react-icons/fa";

const Innovation_Consulting = () => {
    return (
        <>
            <div className="ic_main db">
                <div className="ic_inner backgroundimage">
                    <Header />
                    <div className="container">
                        <div className="main-heading">
                            <div className="heading-main ic_c"><h1>Innovation Consulting Agency</h1></div>
                            <div className="heading-main "><p>
                                We assist companies in looking for new technology-driven opportunities by addressing operational efficiency, expansion, technology, and business management challenges. Our approach to innovation consulting combines best practices in business analysis, market research, product delivery, and future growth strategy development. *instinctools technology and business consultants provide an unmatched breadth of support to ensure the top-grade quality of your solutions and make them genuinely valuable for your company.
                            </p></div>
                        </div>
                    </div>
                </div>
                <div className="second">
                    <div className="container">
                        <div className="inner-second db">
                            <div className="heading">
                                <h2>Our сontribution:</h2>
                            </div>
                            <div className="item-main">
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="image">
                                            <IoSearch />
                                        </div>
                                        <h3>
                                            Estimate innovation opportunities
                                        </h3>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="image">
                                            <MdContentPaste />
                                        </div>
                                        <h3>
                                        Validate technology change 
                                        </h3>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-inner">
                                        <div className="image">
                                            <FaDatabase />
                                        </div>
                                        <h3>
                                        Align the stakeholders 
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="third db">
                    <div className="container">
                        <div className="item-main50">
                            <div className="item50">
                                <div className="item-inner50">
                                    <h4>Pre-discovery Workshop</h4>
                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <p>For those who want to set their project on the right track from the very beginning, we introduced a Pre-discovery Workshop—a phase of a thorough, profound analysis. In three to five days of an onsite, tailored workshop, our team delves into your business issues and provides the top-level vision of handling them.</p>
                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <p>The pre-discovery workshop results in clearly formulated concepts:</p>
                                    <ul><li className="kadimadi white" >Communication plan</li>
                                    <li className="kadimadi white" >Architecture overview</li>
                                    <li className="kadimadi white" >Roadmap for further phases</li>
                                    <li className="kadimadi white" >UI/UX concept</li>
                                    <li className="kadimadi white" >Vision & scope</li>
                                    <li className="kadimadi white" >Project charter</li></ul>
                                </div>
                            </div>
                            <div className="item50">
                                <div className="item-inner50">
                                    <img src="../assets/images/innovation-consulting-img2.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Innovation_Consulting;