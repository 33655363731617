import React from "react";
import AboutUs from "./Aboutus";
import Header from "./Header";
const About = () => {
    return (
        <>

            <div className="home-main ">
                <div className="hire">
                    <Header/>

                <AboutUs />
                </div>
            </div>

        </>
    );
}
export default About;