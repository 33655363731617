import React from "react";
const Precption = () => {
    return (
        <>
            <div className="preception db">
                <div className="container">
                    <div className="inner-preception">
                        <div className="item">
                            <div className="item-inner">
                                <div className="item-img">
                                    <img src="assets/images/1.webp" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-inner">
                                <h2>
                                Perception-Enabled Technology</h2>
                                <p>Systems that can interpret and respond to sensory data from their surroundings using sensors, cameras, and advanced algorithms. These technologies aim to emulate human perception abilities, enabling machines to recognize and react to various stimuli, such as visual, auditory, or tactile inputs. This often involves artificial intelligence (AI) and machine learning (ML) to process and analyze sensory data, leading to applications in autonomous vehicles, robotics, smart homes, and assistive devices. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Precption;