import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './Home';
import Contact from './Contact';
import Technologies from './Technologies';
import Carrer from './Carrer.';
import Service from './Service';
import About from './About';

import HireUs from './HireUs';
import PrivacyPolicy from './Privacy-Policy';
import TC from './T&C';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './Footer';
import CloudComputing from './Service/Cloud_Computing';
import Csd from './Service/Custom_Software_Development';
import Website_Devlopment_designing from './Service/Website_Development_Designing';
import Application_Development_Designing from './Service/Application_Development_Designing';
import UiUx from './Service/UiUx';
import Saas_Devlopment from './Service/SaaS_Development';
import Crm from './Service/Crm';
import Tebt from './Service/Technology_Enabled_Business_Transformation';
import Innovation_Consulting from './Service/Innovation_Consulting';
import Operation_Consulting from './Service/Operation_Consulting';
import E_Commerce from './Service/E-Commerce';
import Fintech from './Service/Fintech';
import Manufacturing_Tracking_Software from './Service/Manufacturing_Tracking_Software';
import HealthCare from './Service/Health_Care';
import Education_ELearning from './Service/Education_E-Learning';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Technologies" element={<Technologies />} />
        <Route path="/Carrer" element={<Carrer />} />
        <Route path="/Service" element={<Service />} />
        <Route path="/Hire Us" element={<HireUs />} />
        <Route path="/About" element={<About />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Terms and Conditions" element={<TC />} />
        <Route path="/Cloud Computing" element={<CloudComputing />} />
        <Route path="/Custom_Software_Development" element={<Csd />} />
        <Route path="/Website_Devlopment_designing" element={<Website_Devlopment_designing />} />
        <Route path="/Application_Development_Designing" element={<Application_Development_Designing />} />
        <Route path="/UIUX" element={<UiUx />} />
        <Route path="/SaaS" element={<Saas_Devlopment />} />
        <Route path="/CRM Consulting" element={<Crm />} />
        <Route path="/Technology Enabled Business Transformation" element={<Tebt />} />
        <Route path="/Innovation Consulting" element={<Innovation_Consulting />} />
        <Route path="/Operation Consulting" element={<Operation_Consulting />} />
        <Route path="/E Commerce" element={<E_Commerce />} />
        <Route path="/Fintech" element={<Fintech />} />
        <Route path="/Manufacturing Tracking Software" element={<Manufacturing_Tracking_Software />} />
        <Route path="/Health Care" element={<HealthCare />} />
        <Route path="/Education & E-Learning" element={<Education_ELearning />} />
      </Routes>
     <Footer/>
    </BrowserRouter >

  </>

);
